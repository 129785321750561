.CollectionFilter {
  color: $color-text-gray;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include media-breakpoint-down(xs) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-menu;
    // font-size: 16px;
  }

  html:not(.show-modal) & {
    pointer-events: none;
  }
}

.CollectionFilter-bg {
  @include media-breakpoint-down(xs) {
    @include absoluteAllSides();
    position: fixed;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s opacity;
  }

  html:not(.show-modal) & {
    opacity: 0;
  }
}

.CollectionFilter-modal {
  @include media-breakpoint-down(xs) {
    @include res-prop("padding-top", $site-header-heights);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: transform 0.6s;
    left: 15%;
    flex-direction: column;
    background-color: color('green-1');
    html:not(.show-modal) & {
      transform: translateX(110%);
    }
  }
}

.CollectionFilter-header {
  @include res-prop("height", $site-header-heights);
  @include res-prop(("padding-right", "padding-left"), $grid-container-paddings);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #fff;
  .Icon {
    margin-left: 0.25rem;
  }
}

.CollectionFilter-close {
  pointer-events: auto;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  color: $color-text-gray;
  outline: none !important;
  transform: scale(0.7);
  .Icon {
    stroke-width: $icon-stroke-width * 1.6;
  }
}

.CollectionFilter-contentBg {
  @include media-breakpoint-up(sm) {
    background-color: color('green-1');
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleY(99.9%);
    transition: 0.5s transform cubic-bezier("easeInOutCubic");
    transform-origin: center top;
    html:not(.show-modal) & {
      transform: scaleY(0);
    }
  }
}

.CollectionFilter-content {
  @include media-breakpoint-up(sm) {
    z-index: 1000;
    position: absolute;
    width: 100%;
    display: flex;
    height: unset !important;
  }

  @include media-breakpoint-down(xs) {
    // height for scroll set in js
    overflow-y: auto;
  }
}

.CollectionFilter-section {
  @include media-breakpoint-down(xs) {
    border-bottom: 1px solid #fff;
  }

  @include media-breakpoint-up(sm) {
    @include res-prop(("padding-right", "padding-left", "padding-top"), $grid-container-paddings);
    padding-bottom: 5rem;
    flex: 1;
    position: relative;
    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background: #fff;
        transition: transform 0.3s;
        transform: scaleY(99.9%);
        transition: 0.5s transform cubic-bezier("easeInOutCubic");
        transform-origin: center top;
        html:not(.show-modal) & {
          transform: scaleY(0);
        }
      }
    }

    span,
    ul {
      display: inline-block;
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.25s;
      transition-property: transform, opacity;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier("easeOutCubic");
      html:not(.show-modal) & {
        transition-delay: 0.75s, 0s;
        transform: translateY(2rem);
        opacity: 0;
      }
    }
  }
}

.CollectionFilter-sectionHeader {
  font-weight: 500;
  padding-bottom: 1.5rem;
  @include media-breakpoint-down(xs) {
    @include res-prop(("padding-right", "padding-left"), $grid-container-paddings);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
  }

  @include media-breakpoint-up(sm) {
    pointer-events: none;
  }
}

.CollectionFilter-sectionHeaderClose {
  @include media-breakpoint-down(xs) {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    transition: transform 0.6s;
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: opacity 0.3s;
    }
    &::before {
      background: url(svg("plus", "fill: none; stroke: #4a4a4a; stroke-width: 1px")) center/contain no-repeat;
    }
    &::after {
      opacity: 0;
      background: url(svg("minus", "fill: none; stroke: #4a4a4a; stroke-width: 1px")) center/contain no-repeat;
    }

    .is-open & {
      transform: rotate(180deg);
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

.CollectionFilter-sectionContent {
  li {
    @include p-small();
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }

  a {
    color: $color-text-gray;
    position: relative;
    display: inline-flex;
    align-items: center;
    opacity: 0.8;
  }

  a.is-disabled {
     opacity: 0.4;
     pointer-events: none;
  }

  a.is-active {
    &:before {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      left: -1.25rem;
      $style: #{"fill: none; stroke: " + $color-text-gray + "; stroke-width: " + $icon-stroke-width};
      background: url(svg("flag", #{$style})) center/contain no-repeat;
    }
  }

  @include media-breakpoint-down(xs) {
    @include res-prop(("padding-right", "padding-left"), $grid-container-paddings);

    li {
      padding-left: 1.25rem;
    }

    a {
      padding-bottom: 0.5rem;
    }

    a.is-active {
      &:before {
        left: -1.25rem;
      }
    }

    li:last-child a {
      padding-bottom: 1.5rem;
    }
  }

  @include media-breakpoint-up(sm) {
    li a {
      transition: transform 0.3s;
    }

    li:hover a {
      // transform: translateX(0.5rem);
    }

    &.collapse {
      display: block;
      height: auto !important;
      visibility: visible;
    }

    &.collapsing {
      position: relative;
      height: unset !important;
      overflow: hidden;
    }

  }
}

.GalleryText-mobile {
  overflow-y: hidden;
  .swiper-container {
    overflow: visible;
    margin-bottom: 2rem;
  }

  .swiper-slide {
    width: 90%;
    &:not(:last-child) {
      @include res-prop("margin-right", $grid-container-paddings);
    }
    &:only-child {
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.GalleryText-desktop {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.GalleryText-desktopMedia {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(5);
    @include make-col-offset(1);
    margin-bottom: 0;
  }
}

.GalleryText-figureWrapper {
  height: 85vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.GalleryText-desktopFigure {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: $color-media-placeholder;
}

// mobile
.GalleryText-figure {
  position: relative;
  width: 100%;
  padding-bottom: 4 / 3.5 * 100%;
  overflow: hidden;
  background: $color-media-placeholder;
}

.GalleryText-image {
  @include lazyloaded();
  @include imageBg();
}

.GalleryText-prose {
  @include media-breakpoint-up(sm) {
    margin: auto;
    @include make-col(5)
  }

  @include media-breakpoint-up(md) {
    @include make-col(4)
  }
}



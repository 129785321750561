@each $page in $tpl-green {
  .tpl-#{$page} .PageHeaderSolid {
    background-color: color('green-1');
    .custom-select {
      background-color: color('green-1');
      border: 1px solid #fff;
      border-radius: 7px;
      color: #000;
      height: auto;
      padding: 0.75rem 1rem;
    }
  }
}

@each $page in $tpl-gray {
  .tpl-#{$page} .PageHeaderSolid {
    background-color: $color-bg-light;
  }
}

.PageHeaderSolid {
  @include section-spacer(("padding-top", "padding-bottom"));

  background-color: color('green-1');

  h1 em {
    color: #fff;
    font-style: normal;
  }
}

.PageHeaderSolid-mainCol {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
}

.PageHeaderSolid-row {
  align-items: center;
}

.PageHeaderSolid-titleCol {
  @include media-breakpoint-up(sm) {
    @include make-col(7)
  }

  @include media-breakpoint-up(md) {
    @include make-col(6, 10)
  }
}

.PageHeaderSolid-selectCol {
  @include media-breakpoint-down(xs) {
    position: relative;
    top: 6rem;
    transform: translateY(map_get($grid-container-paddings, xs) * -1);
    font-size: 16px;
    & > *:not(:first-child) {
      margin-top: 1rem;
    }
    & > *:first-child {
      margin-top: -2.5rem;
    }
    .custom-select {
      font-size: 16px;
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(5);
    display: flex;
    align-items: center;
    & > *:not(:first-child) {
      margin-left: 1rem;
    }
    div {
      flex-shrink: 0;
    }
  }

  @include media-breakpoint-up(md) {
    @include make-col(4, 10)
  }
}

.PageHeaderSolid-searchCol {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

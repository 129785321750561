.CollectionFiltered {
  ul {
    // do not remove
    margin-right: -1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    border-radius: 7px;
    border: 1px solid #000;
    margin-right: -5px;
    @include res-prop("margin-top", $grid-container-paddings);
    margin-right: 1rem;
    a {
      @include p-xsmall();
      padding: 0.35em 0.75em;
      display: inline-block;
    }
  }

  .Icon {
    transform: scale(0.65);
    stroke-width: $icon-stroke-width * 1.4;
    margin-left: 0.75rem;
  }
}

.ProjectPreview-title,
.ProjectPreview-subtitle {
  margin: 0;
}

.ProjectPreview-title {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.ProjectPreview-subtitle {
  color: $color-text-light;
}

.Gallery {
  @include section-spacer(("padding-top", "padding-bottom"));
  background: $color-bg-light;
  .swiper-slide {
    padding-bottom: 3/4 * 100%;
    height: auto;
  }
  .swiper-pagination {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    &:before {
      border-color: $color-text-gray;
    }
    &:after {
      background-color: $color-text-gray;
    }
  }
  .swiper-button-disabled {
    opacity: 0.3;
  }
  @include media-breakpoint-down(xs) {
    @include section-spacer(("padding-top"), 0.5);
  }
}

.Gallery-mainCol {
  margin-left: auto;
  margin-right: auto;
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
}

.Gallery-title {
  text-align: center;
  margin-bottom: 2.5rem;
}

.Gallery-galleryWrapper {
  position: relative;
  @include media-breakpoint-up(sm) {
    @include res-prop(("margin-left", "margin-right"), $grid-container-paddings, 1.4);
  }
}

.Gallery-image {
  @include lazyloaded();
  @include absoluteAllSides();
  background: center/contain no-repeat;
}

.Gallery-video {
  @include absoluteAllSides();
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.Gallery-next {
  @include res-prop("right", $grid-container-paddings, -3);
  color: $color-text-gray;
  position: absolute;
  top: 50%;
  width: 30px;
  text-align: right;
  transform: translateY(-50%);
  outline: none;
  span {
    transition: 0.2s transform;
  }
  &:hover span {
    transform: translate(20%);
  }
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.Gallery-prev {
  @include res-prop("left", $grid-container-paddings, -3);
  color: $color-text-gray;
  position: absolute;
  top: 50%;
  width: 30px;
  text-align: right;
  transform: translateY(-50%) rotate(-180deg);
  outline: none;
  span {
    transition: 0.2s transform;
  }
  &:hover span {
    transform: translate(20%);
  }
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

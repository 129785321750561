.Menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-menu;
  color: #fff;
  a {
    color: #fff;
  }
  html:not(.show-menu) & {
    pointer-events: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.Menu-bg {
  @include absoluteAllSides();
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s;
  html:not(.show-menu) & {
    opacity: 0;
  }
}

.Menu-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: $color-footer-bg;
  transform: translateX(110%);
  transition: transform 0.6s;
  left: 15%;
  @include media-breakpoint-up(sm) {
    left: 50%;
  }
  @include media-breakpoint-up(md) {
    left: 65vw;
  }
  html.show-menu & {
    transform: translateX(0);
  }
}

.Menu-header {
  @include res-prop("height", $site-header-heights);
  @include res-prop("padding-right", $grid-container-paddings);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
}

.Menu-close {
  pointer-events: auto;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  color: #fff;
  outline: none !important;
}

.Menu-content {
  @include absoluteAllSides();
  @include res-prop("padding", $grid-container-paddings);
  @include res-prop("padding-top", $site-header-heights, 0.5);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.Menu-mainNavLabel {
  font-size: 12px;
  display: block;
  margin-bottom: 0.5rem;
}

.Menu-mainNav {
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 20vh;

  & > ul > li > a {
    @include h3();
    @include heading();
    display: inline-block;
    line-height: 1;
  }

  ul ul li {
    opacity: 0.7;
    @include p-xlarge();
    display: flex;
    &:first-child {
      padding-top: 1rem;
    }
    &::before {
      content: '';
      width: rem(18px);
      height: rem(22px);
      margin-right: 0.5rem;
      background: url(svg("arrow", "fill: none; stroke: white; stroke-width: 1.3"));
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .is-dropdown {
    margin-bottom: 2.5rem;
    & > a {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      &::after {
        content: "";
        width: rem(20px);
        height: rem(20px);
        background-image: url(svg("close", "fill: none; stroke: white; stroke-width: 1.4"));
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(10px) rotate(-135deg) scale(0.55);
        transition: transform 0.6s;
      }
      &.is-open::after {
        transform: translateX(10px) rotate(0) scale(0.55);
      }
    }
  }
}

.Menu-accordionHeader {
  display: flex;
  align-items: center;
  h2 {
    margin-right: 1rem;
  }
  .Icon {
    stroke-width: $icon-stroke-width * 1.4;
  }
  &.is-open span {
    transform: rotate(0deg) scale(0.55);
  }
}

.Menu-accordionItem {
  display: flex;
  a {
    padding-top: 0rem;
    margin-left: 0.5rem;
  }
}

.Menu-footer {
  margin-top: auto;
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.Menu-footerNav {
  li:not(:last-child) {
    a {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
  a {
    @include p-xlarge();
    @include heading();
  }
  @include media-breakpoint-down(xs) {
    margin-bottom: 1.25rem;
  }
}

.Menu-footerLanguages {
  ul {
    display: flex;
    li {
      opacity: 0.3;
      &:not(:last-child) {
        margin-right: 0.75rem;
      }
      &.is-active {
        opacity: 1;
      }
    }
  }
}

.InspirationalDetail-galleryCol {
  @include media-breakpoint-up(sm) {
    margin-left: auto;
    margin-right: auto;
    @include make-col(8)
  }
}

.InspirationalDetail-galleryCol {
  @include section-spacer("margin-bottom");
  p {
    margin: 4.5rem 0 0;
  }

  p + p {
    margin: 1rem 0 0 ;
  }
}

.InspirationalDetail-formCol {
  @include media-breakpoint-up(sm) {
    margin-left: auto;
    margin-right: auto;
    @include make-col(8)
  }

  @include media-breakpoint-up(sm) {
    @include make-col(6)
  }
}

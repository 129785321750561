.Prose {
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
}

.Banner {
  display: flex;
  flex-wrap: wrap;

  .Thumbnail {
    margin-bottom: 0;
    &:after {
      content: "";
      display: block;
      @include absoluteAllSides();
      background: rgba(0 , 0, 0, 0.2);
      transform: translateZ(0);
    }
  }
}

.Banner-wrapper {
  flex: 1 0 100%;
  position: relative;
  border-bottom: 1px solid white;

  @include media-breakpoint-up(sm) {
    flex: 1 0 50%;
    &:first-child .Thumbnail{
      border-right: 1px solid white;
    }
  }
}

.Banner-title {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  @include res-prop(("padding-left", "padding-top"), $grid-container-paddings);
  z-index: 1;
  color: #fff;
}

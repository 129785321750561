html {
  @include font-smoothing();
  // @include fluid-root-font-size();
  @include res-prop("font-size", $root-font-size);
  // -webkit-tap-highlight-color: rgba($color-text, 0.2);
}

body {
  overscroll-behavior-y: none; // prevent overscroll on body
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .Main {
    flex-grow: 2;
  }
}

[data-accordion-toggle] {
  outline: none !important;
}

svg {
  overflow: visible;
}

figure {
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
}

a {
  color: #000;

  &:hover {
    text-decoration: none;
    color: #000;
  }

  &[href^="tel:"] {
    text-decoration: none !important;
  }
}

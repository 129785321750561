@each $page in $tpl-green {
  .tpl-#{$page} .SiteHeader:before {
    background-color: color('green-1');
  }
}

@each $page in $tpl-gray {
  .tpl-#{$page} .SiteHeader:before {
    background-color: $color-bg-light;
  }
}

.SiteHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-header;
  pointer-events: none;
  transition: transform 0.2s;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: -1;
  }
  .mobile &,
  .tablet & {
    position: fixed;
  }

  &.scroll-down {
    position: fixed;
    animation: scrollDown 0.3s forwards;
  }

  &.scroll-up {
    position: fixed;
    animation: scrollUp 0.3s forwards;
  }
}

.SiteHeader-container {
  @include res-prop("height", $site-header-heights);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SiteHeader-logo {
  pointer-events: auto;
  height: 50%;
  margin-right: auto;
}

.SiteHeader-menuToggle {
  pointer-events: auto;
  position: relative;
  padding: 0;
  border: 0;
  font-size: 0;
  outline: none !important;
  cursor: pointer;
  margin-left: 0.5rem;
  background-color: transparent;
}

.SiteHeader-search {
  pointer-events: auto;
}

@keyframes scrollDown {
  0% {
    transform: translate3d(0, 0 ,0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes scrollUp {
  0% {
    transform: translate3d(0, -100% ,0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.Accordion {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .ArcDetail & {
    margin: 2.5rem 0;
    padding: 0;
    li:first-child {
      border-top: 1px solid $color-list-border;
    }
  }
}

.Accordion-listItem {
  border-bottom: 1px solid $color-list-border;
}

.Accordion-row {
  .ArcDetail & {
    margin: 0;
  }
}

.Accordion-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
  .ArcDetail & {
    padding: 0;
    max-width: 100%;
    flex: 100%;
  }
}

.Accordion-header {
  display: flex;
  justify-content : space-between;
  align-items: center;
  padding: 1rem 0;
}

.Accordion-headerTitle {
  margin: 0;
}

.Accordion-headerButtonWrapper {
  height: rem(56px);
  width: rem(56px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-bg-light;
  border-radius: 50%;
}

.Accordion-headerButton {
  transform: rotate(90deg);
  position: relative;
  transition: transform 0.6s;
  height: 1.5rem;
  width: 1.5rem;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s;
    transform: rotate(90deg);
  }
  &::before {
    background: url(svg("plus", "fill: none; stroke: #4a4a4a; stroke-width: 1px")) center/contain no-repeat;
  }
  &::after {
    opacity: 0;
    background: url(svg("minus", "fill: none; stroke: #4a4a4a; stroke-width: 1px")) center/contain no-repeat;
  }

  .is-open & {
    transform: rotate(270deg);
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

.Accordion-bodyContent {
  padding: 1.5rem 0 2.5rem;
}

.CustomNav {
  border-top: 1px solid rgba(#fff, 0.8);
  background-color: color('green-1');
  margin-top: -3.6rem;
  position: relative;
  a {
    color: color('black-18');
  }
  ul {
    list-style: none;
    margin: 0;
  }
  &::after {
    content: "";
    @include absoluteAllSides();
    @include res-prop('width', $grid-container-paddings);
    left: unset;
    background-image: linear-gradient(to left, color('green-1'), color('green-1') 20%,  rgba(color('green-1'), 0) 100%);
    pointer-events: none;
  }
  &::before {
    content: "";
    @include absoluteAllSides();
    @include res-prop('width', $grid-container-paddings);
    z-index: 1;
    right: unset;
    background-image: linear-gradient(to right, color('green-1'), color('green-1') 20%, rgba(color('green-1'), 0) 100%);
    pointer-events: none;
  }
}

.CustomNav-list {
  @include res-prop("padding-left", $grid-container-paddings);
  display: flex;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.CustomNav-listItem {
  a {
    display: inline-block;
    padding: 1rem 0;
    margin-right: 1.5rem;
    &.is-active {
      text-decoration: underline;
    }
    @include media-breakpoint-up(sm) {
      margin-right: 2rem;
    }
  }

  &:last-child a {
    @include res-prop("padding-right", $grid-container-paddings);
    margin-right: 0;
  }
}

.CustomNav-headingCounter {
  opacity: 0.4;
}

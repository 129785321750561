.ProjectForm {
	width: 100%;

	@include media-breakpoint-down(xs) {
		select {
			&:not(:first-child) {
				margin-top: 1rem;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		display: flex;
		select {
			&:not(:first-child) {
				margin-left: 1rem;
			}
		}
	}
}

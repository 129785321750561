.ProjectRelated {

}

.ProjectRelated-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include content-spacer("margin-bottom", 0.5);
}

.ProjectRelated-cardRow {
  @include content-spacer("margin-top", -0.5);
}

.ProjectRelated-cardCol {
  @include content-spacer("margin-top", 0.5);

  @include media-breakpoint-up(sm) {
    @include make-col(4);

    @include quantity-exactly(2, '.ProjectRelated-cardCol') {
      @include make-col(6);
    }
  }
}

.ProjectRelated-headerLink {
  &:hover {
    text-decoration: underline;
  }
}

.ArcDetail-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.ArcDetail .sub-heading {
  font-weight: 700;
  font-size: 1rem;
}

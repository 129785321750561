.ProjectList {
  min-height: 30vh;
  @include res-prop("margin-top", $grid-container-paddings, 2);
  @include section-spacer("margin-bottom");
}

.ProjectList-mainCol {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }

  .InspirationList & {
    @include media-breakpoint-up(md) {
      @include make-col(12);
    }
  }
}

.ProjectList-col {
  @include media-breakpoint-up(sm) {
    @include make-col(6);

    &:nth-child(8n + 1),
    &:nth-child(8n + 2) {
      @include make-col(6);
    }

    &:nth-child(8n + 3),
    &:nth-child(8n + 8) {
      @include make-col(12);

      .Thumbnail {
        padding-bottom: 9 / 16 * 100%;
      }
    }
  }
}

.InspirationList .ProjectList-col {
  @include media-breakpoint-up(sm) {
    &:nth-child(odd),
    &:nth-child(even) {
      @include make-col(4);

      .Thumbnail {
        padding-bottom: 3 / 4 * 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(odd),
    &:nth-child(even) {
      @include make-col(3);
    }
  }
}

.ProjectList-row {
  @include content-spacer("margin-top", -1);
}

.ProjectList-col {
  @include content-spacer("margin-top");
}

.ProjectList-wrapper {
  min-height: 30vh;
  margin-top: 8.53333vw;
  margin-bottom: 6rem;
}

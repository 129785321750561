.CataloguePreview-figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 3.45 / 2.5 * 100%;
  background: $color-media-placeholder;
  margin-bottom: 1rem;
}

.CataloguePreview-image {
  @include lazyloaded();
  @include imageBg();
}

.CataloguePreview-title {
  font-family: $font-family-sans-serif;
  margin: 0;
}

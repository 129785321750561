.DistributorList {
  ul {
    list-style: none;
    padding: 0;
  }

  p {
    margin: 0;
  }

  a {
    display: block;
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
}

.DistributorList-mainCol {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
}

.DistributorList-row {
  @include content-spacer("margin-top", -1);
}

.DistributorList-col {
  &:first-child {
    margin-right: 100%;
  }

  @include content-spacer("margin-top");
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  @include media-breakpoint-up(xxl) {
    @include make-col(3);
  }
}

.DistributorList-label {
  font-weight: 500;
  border-bottom: 1px solid #979797;
  padding-bottom: 0.4rem;
}

.DistributorList-place {
  margin: 1rem 0 1.5rem 0;
}

.DistributorList-placeTitle {
  margin-bottom: 0.5rem;
}


.DistributorList-placeAddress,
.DistributorList-placeTelephone,
.DistributorList-agentName,
.DistributorList-agentTelephone,
.DistributorList-description,
.DistributorList-agentEmail {
  color: rgba(#000, 0.6);
}

.DistributorList-description {
  padding-top: 0.75rem;
}

.DistributorList-agent {
  & + & {
    margin-top: 0.75rem;
  }
}

.DistributorList-agentName {
  font-weight: 700;
}

.SearchResults {
  min-height: 30vh;
  @include res-prop("margin-top", $grid-container-paddings, 2);
  @include section-spacer("margin-bottom");
}

.SearchResults-itemRow {
  @include content-spacer("margin-top", -1);
}

.SearchResults-itemCol {
  @include content-spacer("margin-top", 1);
  @include make-col(12);
  margin-top: 3rem;

  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }

  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
}

.SearchResults-alertCol {
  margin: auto;
  color: $color-text-gray;

  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }

  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
}

.Image-figureContainer {
  .Image.--full & {
    @include res-prop(("margin-left", "margin-right"), $grid-container-paddings, -1);
  }
}

.Image-figureRow {
  .Image:not(.--full) & {
    @include make-row();
  }
}

.Image-figureCol {
  .Image:not(.--full) & {
    @include make-col-ready();
    @include make-col(12);
  }

  .Image.--narrow & {
    @include media-breakpoint-up(sm) {
      @include make-col(8);

      margin-left: auto;
      margin-right: auto;
    }
  }
}

.Image-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 3 / 4 * 100%;
  background: $color-media-placeholder;
  .Image.--full & {
    @include media-breakpoint-up(sm) {
      padding-bottom: 3 / 4.5 * 100%;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 3 / 5.5 * 100%;
    }
  }
}

.Image-image {
  @include imageBg();
  @include lazyloaded();
}

.Image-caption {
  color: $color-text-gray;
  margin-top: 1rem;

  @include media-breakpoint-up(sm) {
    @include make-col(6);
    @include make-col-offset(1);

    .Image.--narrow & {
      @include make-col(7);
      @include make-col-offset(2);
    }
  }

  @include media-breakpoint-up(md) {
    @include make-col(4);

    .Image.--narrow & {
      @include make-col(5);
      @include make-col-offset(2);
    }
  }

  @include media-breakpoint-up(lg) {
    .Image.--narrow & {
      @include make-col(4);
      @include make-col-offset(2);
    }
  }
}

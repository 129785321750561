.SiteFooter {
  @include res-prop("padding-top", $grid-container-paddings, 2);
  @include res-prop("padding-bottom", $grid-container-paddings);
  color: #fff;
  background: $color-footer-bg;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    color: #fff;
  }
}

.SiteFooter-navCol {
  li {
    @include heading();
    @include h4();
  }
  @include media-breakpoint-down(xs) {
    @include content-spacer("margin-bottom");
  }

  @include media-breakpoint-up(sm) {
    @include make-col(5);
  }
}

.SiteFooter-addressCol {
  @include media-breakpoint-down(xs) {
    @include content-spacer("margin-bottom");
  }

  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  a {
    text-decoration: underline;
  }
}

.SiteFooter-contactCol {
  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }
}

.SiteFooter-mainRow {
  @include content-spacer("margin-bottom", 1);
}

p.SiteFooter-sectionTitle {
  font-weight: 700;
  @include media-breakpoint-up(sm) {
    margin-bottom: 1rem;
  }
}

.SiteFooter-colophonCol {
  display: flex;
  justify-content: space-between;
  color: #AEAEAE;
  a {
    color: #AEAEAE;
  }
  p {
    margin: 0;
  }
  p span {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    p span {
      display: block;
    }
  }
}

.FeatureGallery {
  @include section-spacer(("margin-top", "margin-bottom"), 1);
  &.--background {
    @include section-spacer(("padding-top", "padding-bottom"), 0.5);
    background: $color-bg-light;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .tab-content {
    width: 100%;
  }
  .tab-pane {
    width: 100%;
    display: block;
    opacity: 0;
    transition: opacity 0.25s;
    &.active {
      opacity: 1;
    }
  }
  .nav {
    margin-bottom: 3rem;
    justify-content: center;
    margin-top: -1rem;
  }
  .nav-item {
    margin: 1rem 0.1rem 0;
    @include media-breakpoint-up(sm) {
      margin: 1rem 0.25rem 0;
    }
  }
  .tab-content {
    background: $color-media-placeholder;
    position: relative;
    width: 100%;
    padding-bottom: 3 / 4 * 100%;
    overflow: hidden;
  }
}

.nav-link {
  @include p-small();
  border-radius: 7px;
  border: 1px solid #fff;
  line-height: 1;
  padding: 0.65rem 0.8rem 0.5rem;

  &:hover {
    border-color: #C8CFC6;
  }

  &.active {
    border: 1px solid #000;
  }

  .--background & {
    & {
      border-color: $color-bg-light;
    }

    &:hover {
      border-color: #C8CFC6;
    }

    &.active {
      border: 1px solid #000;
    }
  }
}

.FeatureGallery-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    margin-left: auto;
    margin-right: auto;
    .--imageSmall & {
      @include make-col(6);
    }
  }
}

.FeatureGallery-title {
  margin-bottom: 2rem;
  text-align: center;
}

.FeatureGallery-nav {
  display: flex;
}

.FeatureGallery-figure {
  @include absoluteAllSides();
}

.FeatureGallery-image {
  @include lazyloaded();
  @include imageBg();
}

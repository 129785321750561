.Hotspots {
  @include section();
}

.Hotspots-wrapper {
  position: relative;
}

.Hotspots-col {
  .Hotspots.--tight & {
    @include media-breakpoint-up(md) {
      @include make-col(10);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.Hotspots-figure {
  background-color: $color-media-placeholder;
}

.Hotspots-image {
  @include lazyloaded();
}

.Hotspots-itemList,
.Hotspots-overlayList {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Hotspots-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.Hotspots-dot {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  &:before {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff;
  }
  &:after {
    width: 50%;
    height: 50%;
    border: 2px solid #fff;
    transform-origin: 50% 50%;
    animation: dot-zoom 2s cubic-bezier("easeOutQuart") infinite;
  }
}

.Hotspots-overlay {
  padding: 0rem;
  background-color: #fff;
  width: 20rem;
  display: none;
  .fancybox & {
    display: block;
  }
}

.Hotspots-overlayLink {
  color: inherit !important;
  text-decoration: none !important;
}

.Hotspots-overlayFigure {
  padding-bottom: 100%;
  padding-bottom: 3 / 4 * 100%;
  background-color: $color-media-placeholder;
  position: relative;
}

.Hotspots-overlayImage {
  @include lazyloaded();
  @include imageBg();
}

.Hotspots-overlayContent {
  padding: 1.5rem 1rem;
}

.Hotspots-contentTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem;
  margin-bottom: 1rem;
  .Icon {
    flex-shrink: 0;
    margin-left: 1rem;
  }
}

.Hotspots-contentDescription {
  margin: 0;
}

@keyframes dot-zoom {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.3);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

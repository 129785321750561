.swiper-pagination {
  pointer-events: auto;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  z-index: 10;
}

.swiper-pagination-bullet {
  position: relative;
  margin: 0 !important;
  &:not(:last-child) {
    margin: 0 1rem 0 0 !important;
  }
  background: transparent;
  opacity: 1;
  @include media-breakpoint-up(sm) {
    &:not(:last-child) {
      margin: 0 1.5rem 0 0 !important;
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 11px;
    width: 11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.4);
    border-radius: 50%;
  }
  &:before {
    border: 1px solid #fff;
    transition: 0.4s transform;
  }
  &:after {
    background: #fff;
    transition: 0.4s transform, 0.4s opacity;
  }
}

.swiper-pagination-bullet-active {
  &:before {
    transform: translate(-50%, -50%) scale(1);
    transition: 0.4s transform;
  }
  &:after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    transition: 0.4s transform, 0.4s opacity;
  }
}

.Text {

}

.Text-prose {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    @include make-col(6);
    margin: auto;
  }

  .Text.--alignRight & {
    @include media-breakpoint-up(sm) {
      @include make-col(7);
      margin-left: auto;
      margin-right: 0;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @include heading();
}

.h1-large {
  @include h1-large(); //60px
}

h1,
.h1 {
  @include h1(); //52px
}

.h1-small {
  @include h1-small(); //30px
}

h2,
.h2 {
  @include h2(); //46px
}

h3,
.h3 {
  @include h3(); //42px
}

h4,
.h4 {
  @include h4(); //36px
}

h5,
.h5 {
  @include h5(); //30px
}

h6,
.h6 {
  @include h6(); //24px
}

.sub-heading {
  @include p-xlarge(); //22px
  font-weight: 500;
  margin-bottom: 2rem;
  font-family: $font-family-sans-serif;
}

.p-xlarge {
  @include p-xlarge(); //22px
}

.p-large {
  @include p-large(); //20px
}

.p-small {
  @include p-small(); //14px
}

.p-xsmall {
  @include p-xsmall(); //12px
}

.strong {
  font-weight: 700;
}

.p-label {
  color: $color-label;
}

.text-muted {
  color: $color-text-muted;
}

.Logo {
  // width: 16.7vw;
  // min-width: 9rem;
  // max-width: 12rem;
  height: 100%;
  width: auto;
  fill: currentColor;

  // @include media-breakpoint-up(sm) {
  //   width: 160px;
  // }

  // @include media-breakpoint-up(md) {
  //   width: 190px;
  // }

  // @include media-breakpoint-up(lg) {
  //   width: 232px;
  // }

  // &-type {
  //   transition: opacity .6s;
  //   .is-scrolled & {
  //     @include media-breakpoint-up(sm) {
  //       opacity: 0;
  //     }
  //   }
  // }

}

.Thumbnail {
  position: relative;
  width: 100%;
  padding-bottom: 3 / 4 * 100%;
  margin-bottom: 1rem;
  background: $color-media-placeholder;
  overflow: hidden;
}

.Thumbnail-wrapper {
  @include absoluteAllSides();
  transition: 1s transform;

  a:hover & {
    transition: 2s transform;
    transform: scale(1.1);
  }

  &.Thumbnail-disableZoom {
    a:hover & {
      transition: none;
      transform: none;

      & .Thumbnail-image:nth-child(2) {
        opacity: 0;
      }
    }
  }
}

.Thumbnail-image {
  @include lazyloaded();
  @include imageBg();
}

.Button {
  position: relative;
  border: 2px solid $color-button-border;
  border-radius: 10px;
  padding: 0.75rem 1rem;
  min-width: 12.5rem;
  background: #fff;
  color: #000;
  &::before,
  &::after {
    content: "";
    height: 1.8rem;
    transition: transform 0.5s;
    background: #fff;
    top: 50%;
    position: absolute;
    width: 6px;
  }
  &::before {
    left: 0;
    transform: translate(-50%, -50%) scaleY(0.99);
    transform-origin: center top;
  }
  &::after {
    right: 0;
    transform: translate(50%, -50%) scaleY(0.99);
    transform-origin: center bottom;
  }
  &:hover {
    &::before {
      transform: translate(-50%, -50%) scaleY(0);
    }

    &::after {
      transform: translate(50%, -50%) scaleY(0);
    }
  }
}

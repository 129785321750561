.CustomFilter {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include media-breakpoint-down(xs) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-menu;
  }

  @include media-breakpoint-down(xs) {
    html:not(.show-modal) & {
      pointer-events: none;
    }
  }
}

.CustomFilter-bg {
  @include media-breakpoint-down(xs) {
    @include absoluteAllSides();
    position: fixed;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s opacity;
  }

  html:not(.show-modal) & {
    opacity: 0;
  }
}

.CustomFilter-modal {
  @include media-breakpoint-down(xs) {
    @include res-prop("padding-top", $site-header-heights);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: transform 0.6s;
    left: 15%;
    flex-direction: column;
    background-color: #fff;
    html:not(.show-modal) & {
      transform: translateX(110%);
    }
  }
}

.CustomFilter-header {
  @include res-prop("height", $site-header-heights);
  @include res-prop(("padding-right", "padding-left"), $grid-container-paddings);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(#000, 0.1);
  .Icon {
    margin-left: 0.25rem;
  }
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.CustomFilter-list {
  overflow-y: auto;
  @include media-breakpoint-up(sm) {
    // fix js mobile height
    height: auto !important;
  }
}

.CustomFilter-listItem {
  border-bottom: 1px solid rgba(#000, 0.1);
  @include media-breakpoint-down(xs) {
    @include res-prop(("padding-right", "padding-left"), $grid-container-paddings);
  }
  @include media-breakpoint-up(sm) {
    &:first-child {
      border-top: 1px solid rgba(#000, 0.1);
    }
  }
}

.CustomFilter-listHeader {
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: 1.5rem 0;
    font-weight: 500;
  }
}

.CustomFilter-listCloseButton {
  height: 1.4em;
  width: 1.4rem;
  position: relative;
  transition: transform 0.6s;
  transform: rotate(0.1deg);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s;
  }
  &::before {
    background: url(svg("plus", "fill: none; stroke: #4A4A4A; stroke-width: 1px")) center/contain no-repeat;
  }
  &::after {
    opacity: 0;
    background: url(svg("minus", "fill: none; stroke: #4A4A4A; stroke-width: 1px")) center/contain no-repeat;
  }
  .is-open & {
    transform: rotate(180deg);
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

.CustomFilter-listBody {
  li {
    padding-left: 1.25rem;
  }
}

.CustomFilter-listBodyItem {
  @include p-small();
  display: inline-flex;
  align-items: center;
  position: relative;
  opacity: 1;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.is-active {
    &::before {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      left: -1.25rem;
      $style: #{"fill: none; stroke: " + $color-text-gray + "; stroke-width: " + $icon-stroke-width};
      background: url(svg("flag", #{$style})) center/contain no-repeat;
    }
  }

  li li:last-child & {
    padding-bottom: 1.5rem;
    @include media-breakpoint-up(sm) {
      padding-bottom: 1.25rem;
    }
  }

  li li & {
    padding-bottom: 0.5em;
  }
}

.ArcItemList {
  padding-top: 5rem;
  @include res-prop("padding-bottom", $grid-container-paddings, 2);
  background: $color-dark-bg;
  color: #fff;
  a {
    color: #fff;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.ArcItemList-headerCol,
.ArcItemList-mediaCol {
  @include media-breakpoint-up(sm) {
    @include make-col(6)
  }
}


.ArcItemList-contentCol {
  @include media-breakpoint-up(sm) {
    @include make-col(5);
    margin-left: auto;
    margin-right: auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
    margin-left: auto;
    margin-right: auto;
  }
}

.ArcItemList-caption {
  margin-bottom: 0.25rem;
}

.ArcItemList-header {
  margin-bottom: 2.5rem;
}

.ArcItemList-contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.ArcItemList-headerButtons {
  display: flex;
}

.ArcItemList-itemButton {
  padding: 0.3rem 0.75rem;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }

  &.is-active {
    border: 1px solid #C8CFC6;
    border-radius: 7px;
    margin-top: -1px;
    &:last-child {
      margin-right: -1px;
    }
  }
}

.ArcItemList-contentList {
  border-top: 1px solid #fff;
}

.ArcItemList-listItem {
  &:not(:last-child) {
    border-bottom: 1px solid $color-list-border;
  }
  overflow: hidden;
}

.ArcItemList-itemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  margin-left: -2.4rem;
  transition: transform 0.3s;
  &:hover {
    transform: translateX(2.4rem);
  }
}

.ArcItemList-itemSize {
  span {
    width: 1.3rem;
    margin-right: 1rem;
  }
}

.ArcItemList-sizeMm,
.ArcItemList-sizeInch {
  display: none;
}

.show-mm .ArcItemList-sizeMm {
  display: inline;
}

.show-inch .ArcItemList-sizeInch {
  display: inline;
}

.ArcItemList-figure {
  position: relative;
  width: 100%;
  padding-bottom: 3 / 4 * 100%;
  overflow: hidden;
  background: $color-media-placeholder;
  @include media-breakpoint-down(xs) {
    @include content-spacer("margin-bottom");
  }
}

.ArcItemList-image {
  @include lazyloaded();
  @include imageBg();
}

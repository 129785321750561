.PageHeaderCollection-ContentCol {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

.PageHeaderCollection-ContentWrapper {
  @include media-breakpoint-up(sm) {
    position: sticky;
    @include res-prop("top", $site-header-heights);
  }
}

.PageHeaderCollection-HeadingCol {
  @include res-prop("margin-bottom", $grid-container-paddings, 1);
}

.PageHeaderCollection-title {
  margin: 0;
}

.PageHeaderCollection-smallMediaCol {
  @include res-prop("margin-bottom", $grid-container-paddings, 1.5);
  @include make-col(6);
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    @include make-col(4, 6)
  }
}

.PageHeaderCollection-bigMediaCol {
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(6)
  }
}

.PageHeaderCollection-HeadingCaption {
  color: $color-text-gray;
}

.PageHeaderCollection-smallMediaFigure {
  position: relative;
  width: 100%;
  padding-bottom: 3 / 4 * 100%;
  overflow: hidden;
  background: $color-media-placeholder;
}

.PageHeaderCollection-smallMediaImage,
.PageHeaderCollection-bigMediaImage {
  @include lazyloaded();
  @include imageBg();
}

.PageHeaderCollection-bigMediaFigure {
  position: relative;
  width: 100%;
  padding-bottom: 4 / 3 * 100%;
  overflow: hidden;
  background: $color-media-placeholder;
}

.CollectionRelated {
  overflow: hidden;
  padding-bottom: 1rem;

  .swiper-wrapper {
    width: 100%;
  }

  .swiper-container {
    overflow: visible;
    @include make-row();
    @include media-breakpoint-up(sm) {
      // @include res-prop("margin-right", $grid-container-paddings); // da correggere
    }
  }

  .swiper-slide {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
  }

  .swiper-button-disabled {
    opacity: 0.3;
  }

  .swiper-pagination {
    text-align: center;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    &:before {
      border-color: #000;
    }
    &:after {
      background: #000;
    }
  }

  .CollectionPreview {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
}

.CollectionRelated-header {
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @include media-breakpoint-down(xs) {
    text-align: center;
  }
}

.CollectionRelated-headerTitle {
  margin: 0;
}

.CollectionRelated-headerArrows {
  position: relative;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.CollectionRelated-ArrowNext {
  width: 30px;
  text-align-last: right;
  span {
    transition: 0.2s transform;
  }
  &:hover span {
    transform: translate(20%);
  }
}

.CollectionRelated-ArrowPrev {
  width: 30px;
  text-align-last: left;
  span {
    transition: 0.2s transform;
    transform: rotate(-180deg);
  }
  &:hover span {
    transform: rotate(-180deg) translate(20%);
  }
}

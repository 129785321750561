.Cta {
  background: $color-cta-bg;
}

.Cta-wrapper {
  @include section-spacer(("padding-top","padding-bottom"), 0.75);
  @include res-prop(("padding-left", "padding-right"), $grid-container-paddings);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::after {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.05);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transition: transform 0.5s cubic-bezier("easeOutSine");
    z-index: 2;
    transform-origin: 0 center;
  }
  &:hover::after {
    transform: scaleX(1);
  }
}

.Cta-Button {
  height: 4rem;
  width: 4rem;
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.Cta-ButtonArrowWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  transition-delay: 0s;
  .Cta-wrapper:hover & {
    transition-delay: 0.25s;
    transform: translateX(100%);
  }
}

.Cta-ButtonArrow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: transform 0.43s cubic-bezier("easeOutSine");
  &:first-child {
    transform: translate(calc(-150%), -50%);
  }
}

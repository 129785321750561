.PageHeaderBreadCrumb {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    color: $color-text-gray;
    margin-bottom: 0.25rem;
    &:not(:first-child):before {
      content: "/";
      margin: 0 0.25rem 0 0.15rem;
    }
  }

  span {
    @include h4();
    opacity: 0.3;
    font-family: $font-family-sans-serif;
  }
}

.PageHeaderBreadCrumb-subtitle {
  font-size: 1rem;
  font-family: $font-family-sans-serif;
  color: $color-text-light;
}

.SearchForm {
}

.SearchForm-nav {
  list-style: none;
  padding: 0;
  margin: 0 0 0.25rem 0;
  display: flex;

  label {
    margin: 0;
    cursor: pointer;
    color: $color-text-gray;
  }

  input {
    display: none;
  }

  input:checked + span {
    text-decoration: underline;
    color: #000;
  }

  li:not(:first-child) {
    margin-left: 1rem;
  }
}

.SearchForm-form {
  border-bottom: 1px solid #fff;
}

.SearchForm-inputField {
  display: flex;
  align-items: center;
  height: 3.5rem;
}

.SearchForm-textInput {
  @include heading();
  @include h5();
  flex: 1;
  border: 0;
  background: color('green-1');
  height: 100%;
  outline: none;
  margin: 0;
  padding: 0;
}

.SearchForm-buttonInput {
  border: 0;
  background: color('green-1');
  height: 100%;
  padding-right: 0;
  .Icon {
    transition: transform 0.3s;
  }
  &:hover .Icon {
    transform: translateX(0.25rem);
  }
}

.DownloadList {
  margin-top: 2.5rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  + .DownloadList {
    margin-top: 1.5rem;
  }

  .Accordion &:first-child {
    margin-top: 0;
  }
}


.DownloadList-listItem {
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.DownloadList-itemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  span:first-child {
    opacity: 0.6;
  }

  &:hover span:last-child {
    text-decoration: underline;
  }
}

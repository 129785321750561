.ImageGrid-mainCol {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
}

.ImageGrid-col {
  @include media-breakpoint-down(xs) {
    &:first-child {
      @include content-spacer("margin-bottom");
    }
  }

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

.ImageGrid-imageWrapper {
  background: $color-media-placeholder;
  margin-bottom: 1rem;
}

.ImageGrid-image {
  @include lazyloaded();
}

.ImageGrid-caption {
  color: $color-text-gray;
  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
}

.CatalogueList {

  + .CatalogueList {
    margin-top: 1.5rem;
  }
}

.CatalogueList-col {
  @include make-col(6);
  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }
}

.CatalogueList-row {
  @include content-spacer("margin-top", -1);
}

.CatalogueList-col {
  @include content-spacer("margin-top");
}

.ProductGallery {
  position: relative;
  margin-bottom: 4.5rem;
  user-select: none;

  .swiper-pagination {
    left: 0;
    transform: none;
    text-align: left;
    margin-top: 1rem;
  }

  .swiper-pagination-bullet {
    opacity: 0.2;
    &::before,
    &::after {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      &:not(:last-child) {
        margin: 0 1rem 0 0 !important;
      }
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

.ProductGallery-figure {
  position: relative;
  width: 100%;
  padding-bottom: 3 / 4 * 100%;
  background: $color-media-placeholder;
  overflow: hidden;
}

.ProductGallery-image {
  @include lazyloaded();
  @include imageBg();
}

.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: $icon-stroke-width;
  // stroke-linecap: round;
  // stroke-linejoin: round;
  width: 1rem;
  height: 1rem;
}

.Icon-svg {
  width: 100%;
  height: 100%;
}

.Icon--filters {
  width: rem(22px);
  height: rem(14px);
}

.Icon--close {
  width: rem(20px);
  height: rem(20px);
}

.Icon--menu {
  width: rem(24px);
  height: rem(20px);
}

.Icon--arrow {
  width: rem(20px);
  height: rem(16px);
}

.Icon--lens {
  width: rem(24px);
  height: rem(24px);
}

.Icon--round-arrow {
  width: rem(18px);
  height: rem(22px);
}

// .Icon--filters {
//   width: rem(22px);
//   height: rem(14px);
// }

// .Icon--menu {
//   width: rem(22px);
//   height: rem(22px);
//   stroke-width: $icon-stroke-width * 1.1;
// }

// .Icon--facebook,
// .Icon--twitter,
// .Icon--instagram,
// .Icon--youtube,
// .Icon--link,
// .Icon--pinterest,
// .Icon--linkedin {
//   width: rem(20px);
//   height: rem(20px);
//   fill: currentColor;
//   stroke: none;
// }

// .Icon--compare {
//   width: 2rem;
//   height: 2rem;
// }

// .Icon--grid {
//   fill: currentColor;
//   stroke: none;
// }

// .Icon--sun,
// .Icon--moon {
//   width: rem(32px);
//   height: rem(32px);
//   fill: currentColor;
//   stroke-width: 0.3px;
//   fill-rule: evenodd;
//   clip-rule: evenodd;
// }

// .Icon--moon {
//   stroke-width: 1.2px;
// }

.CustomPreview {
  .Thumbnail {
    padding-bottom: 100%;
  }
}

.CustomPreview-title,
.CustomPreview-subtitle {
  margin: 0;
}

.CustomPreview-title {
  color: $color-text-gray;
}

.CustomPreview-subtitle {
  color: $color-text-light;
}



.IconList {
  margin-top: 2.5rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .Accordion & {
    margin-top: -2.5rem;
  }
}

.IconList-wrapper {
  & + .IconList-wrapper {
    margin-top: 2.5rem;
  }
}

.IconList-title {
  @include content-spacer("margin-bottom", 0.5);
  font-weight: 700;
}

.IconList-list {
  display: flex;
}

.IconList-figure {
  display: inline-block;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  .IconList-listItem:not(:last-child) & {
    margin-right: 1rem;
  }
}

.IconList-image {
  @include lazyloaded();
  @include absoluteAllSides();
  background: center/contain no-repeat;
}

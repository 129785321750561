/*
  ------------------------------------------------------------------
  --- GRID ---------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- TYPOGRAPHY ---------------------------------------------------
  ------------------------------------------------------------------
*/
/* stylelint-disable unit-no-unknown */
/* stylelint-enable unit-no-unknown */
/*
  ------------------------------------------------------------------
  --- COLORS -------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- ICONS --------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- z-index ------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- header ------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- Form ---------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- Bootstrap ---------------------------------------------------------
  ------------------------------------------------------------------
*/
/* Font Face */
/* font smoothing */
/* Core Utility to add margin and padding, used by the spacer mixins below */
/* Responsive spacer based on grid-gutter-width values or a given spacer map */
/* Spacer mixins based on global $spacers, accept also negative values */
/* Spacer shortcut for margin bottom */
/* Spacer shortcut for padding */
/* Spacer shortcut for margin-right */
/*
  ------------------------------------------------------------------
  --- IMAGES -------------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- SECTIONS -----------------------------------------------------
  ------------------------------------------------------------------
*/
/*
  ------------------------------------------------------------------
  --- Typography ---------------------------------------------------
  ------------------------------------------------------------------
*/
@import url(../../node_modules/@fancyapps/fancybox/src/css/core.css);
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #111;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container,
.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .container,
    .container-fluid {
      padding-right: 2.22222vw;
      padding-left: 2.22222vw; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .row {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw; }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
    .col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
    .col-xxxl-auto {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1640px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 2400px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxl-first {
    order: -1; }
  .order-xxxl-last {
    order: 13; }
  .order-xxxl-0 {
    order: 0; }
  .order-xxxl-1 {
    order: 1; }
  .order-xxxl-2 {
    order: 2; }
  .order-xxxl-3 {
    order: 3; }
  .order-xxxl-4 {
    order: 4; }
  .order-xxxl-5 {
    order: 5; }
  .order-xxxl-6 {
    order: 6; }
  .order-xxxl-7 {
    order: 7; }
  .order-xxxl-8 {
    order: 8; }
  .order-xxxl-9 {
    order: 9; }
  .order-xxxl-10 {
    order: 10; }
  .order-xxxl-11 {
    order: 11; }
  .order-xxxl-12 {
    order: 12; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; } }

.form-control, .FormBuilder input[type="text"],
.FormBuilder input[type="email"],
.FormBuilder textarea,
.FormBuilder select {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .FormBuilder input[type="text"],
    .FormBuilder input[type="email"],
    .FormBuilder textarea,
    .FormBuilder select {
      transition: none; } }
  .form-control::-ms-expand, .FormBuilder input[type="text"]::-ms-expand,
  .FormBuilder input[type="email"]::-ms-expand,
  .FormBuilder textarea::-ms-expand,
  .FormBuilder select::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .FormBuilder input:-moz-focusring[type="text"],
  .FormBuilder input:-moz-focusring[type="email"],
  .FormBuilder textarea:-moz-focusring,
  .FormBuilder select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus, .FormBuilder input:focus[type="text"],
  .FormBuilder input:focus[type="email"],
  .FormBuilder textarea:focus,
  .FormBuilder select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #c7cec5;
    outline: 0;
    box-shadow: 0 0 3px #c7cec5; }
  .form-control::placeholder, .FormBuilder input[type="text"]::placeholder,
  .FormBuilder input[type="email"]::placeholder,
  .FormBuilder textarea::placeholder,
  .FormBuilder select::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .FormBuilder input:disabled[type="text"],
  .FormBuilder input:disabled[type="email"],
  .FormBuilder textarea:disabled,
  .FormBuilder select:disabled, .form-control[readonly], .FormBuilder input[readonly][type="text"],
  .FormBuilder input[readonly][type="email"],
  .FormBuilder textarea[readonly],
  .FormBuilder select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control, .FormBuilder input[type="date"][type="text"],
.FormBuilder input[type="date"][type="email"],
input[type="time"].form-control,
.FormBuilder input[type="time"][type="text"],
.FormBuilder input[type="time"][type="email"],
input[type="datetime-local"].form-control,
.FormBuilder input[type="datetime-local"][type="text"],
.FormBuilder input[type="datetime-local"][type="email"],
input[type="month"].form-control,
.FormBuilder input[type="month"][type="text"],
.FormBuilder input[type="month"][type="email"] {
  appearance: none; }

select.form-control:focus::-ms-value,
.FormBuilder select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file, .FormBuilder input[type="file"],
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #111;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size],
.FormBuilder select[size], select.form-control[multiple],
.FormBuilder select[multiple] {
  height: auto; }

textarea.form-control,
.FormBuilder textarea {
  height: auto; }

.form-group, .FormBuilder .Inputfield,
.FormBuilder fieldset {
  margin-bottom: 1rem; }

.form-text, .FormBuilder .input-error, .FormBuilder .notes {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check, .FormBuilder .InputfieldCheckbox .InputfieldContent label:not(.custom-control-label),
.FormBuilder .InputfieldCheckboxes .InputfieldContent label:not(.custom-control-label),
.FormBuilder .InputfieldRadios .InputfieldContent label:not(.custom-control-label) {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input, .FormBuilder .InputfieldCheckbox .InputfieldContent input,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input,
.FormBuilder .InputfieldRadios .InputfieldContent input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input[disabled] ~ .form-check-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input[disabled] ~ .form-check-label, .FormBuilder .InputfieldRadios .InputfieldContent input[disabled] ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input[disabled] ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent input[disabled] ~ label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input[disabled] ~ label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent input[disabled] ~ label,
  .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input[disabled] ~ label,
  .FormBuilder .InputfieldRadios .InputfieldContent input[disabled] ~ label,
  .form-check-input:disabled ~ .form-check-label,
  .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled ~ .form-check-label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled ~ .form-check-label,
  .FormBuilder .InputfieldRadios .InputfieldContent input:disabled ~ .form-check-label,
  .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input:disabled ~ label,
  .FormBuilder .InputfieldCheckbox .InputfieldContent input:disabled ~ label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input:disabled ~ label,
  .FormBuilder .InputfieldCheckboxes .InputfieldContent input:disabled ~ label,
  .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input:disabled ~ label,
  .FormBuilder .InputfieldRadios .InputfieldContent input:disabled ~ label {
    color: #6c757d; }

.form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent label,
.FormBuilder .InputfieldRadios .InputfieldContent label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input, .form-check-inline .FormBuilder .InputfieldCheckbox .InputfieldContent input, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-inline input,
  .form-check-inline .FormBuilder .InputfieldCheckboxes .InputfieldContent input, .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-inline input,
  .form-check-inline .FormBuilder .InputfieldRadios .InputfieldContent input, .FormBuilder .InputfieldRadios .InputfieldContent .form-check-inline input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .FormBuilder input:valid[type="text"], .FormBuilder .was-validated input:valid[type="text"],
.was-validated .FormBuilder input:valid[type="email"], .FormBuilder .was-validated input:valid[type="email"],
.was-validated .FormBuilder textarea:valid, .FormBuilder .was-validated textarea:valid,
.was-validated .FormBuilder select:valid, .FormBuilder .was-validated select:valid, .form-control.is-valid, .FormBuilder input.is-valid[type="text"],
.FormBuilder input.is-valid[type="email"],
.FormBuilder textarea.is-valid,
.FormBuilder select.is-valid {
  border-color: #28a745;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .FormBuilder input:valid:focus[type="text"], .FormBuilder .was-validated input:valid:focus[type="text"],
  .was-validated .FormBuilder input:valid:focus[type="email"], .FormBuilder .was-validated input:valid:focus[type="email"],
  .was-validated .FormBuilder textarea:valid:focus, .FormBuilder .was-validated textarea:valid:focus,
  .was-validated .FormBuilder select:valid:focus, .FormBuilder .was-validated select:valid:focus, .form-control.is-valid:focus, .FormBuilder input.is-valid:focus[type="text"],
  .FormBuilder input.is-valid:focus[type="email"],
  .FormBuilder textarea.is-valid:focus,
  .FormBuilder select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid,
.was-validated .FormBuilder textarea:valid, .FormBuilder .was-validated textarea:valid, textarea.form-control.is-valid,
.FormBuilder textarea.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid ~ .form-check-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid ~ .form-check-label, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid ~ .form-check-label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input:valid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated .form-check-input:valid ~ label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid ~ label,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input:valid ~ label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated .form-check-input:valid ~ label,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid ~ label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid ~ label,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input:valid ~ label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated .form-check-input:valid ~ label,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid ~ label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid ~ label, .form-check-input.is-valid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid ~ .form-check-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid ~ .form-check-label, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input.is-valid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid ~ label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input.is-valid ~ label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid ~ label,
.FormBuilder .InputfieldRadios .InputfieldContent .form-check-input.is-valid ~ label,
.FormBuilder .InputfieldRadios .InputfieldContent input.is-valid ~ label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid ~ .valid-feedback, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid ~ .valid-feedback, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid ~ .valid-feedback, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid ~ .valid-feedback, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid ~ .valid-feedback, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:valid ~ .valid-tooltip,
.FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:valid ~ .valid-tooltip,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:valid ~ .valid-tooltip,
.FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:valid ~ .valid-tooltip,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:valid ~ .valid-tooltip,
.FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid ~ .valid-feedback, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid ~ .valid-feedback, .FormBuilder .InputfieldRadios .InputfieldContent input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.FormBuilder .InputfieldCheckbox .InputfieldContent input.is-valid ~ .valid-tooltip,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-valid ~ .valid-tooltip,
.FormBuilder .InputfieldRadios .InputfieldContent input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .FormBuilder input:invalid[type="text"], .FormBuilder .was-validated input:invalid[type="text"],
.was-validated .FormBuilder input:invalid[type="email"], .FormBuilder .was-validated input:invalid[type="email"],
.was-validated .FormBuilder textarea:invalid, .FormBuilder .was-validated textarea:invalid,
.was-validated .FormBuilder select:invalid, .FormBuilder .was-validated select:invalid, .form-control.is-invalid, .FormBuilder input.is-invalid[type="text"],
.FormBuilder input.is-invalid[type="email"],
.FormBuilder textarea.is-invalid,
.FormBuilder select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .FormBuilder input:invalid:focus[type="text"], .FormBuilder .was-validated input:invalid:focus[type="text"],
  .was-validated .FormBuilder input:invalid:focus[type="email"], .FormBuilder .was-validated input:invalid:focus[type="email"],
  .was-validated .FormBuilder textarea:invalid:focus, .FormBuilder .was-validated textarea:invalid:focus,
  .was-validated .FormBuilder select:invalid:focus, .FormBuilder .was-validated select:invalid:focus, .form-control.is-invalid:focus, .FormBuilder input.is-invalid:focus[type="text"],
  .FormBuilder input.is-invalid:focus[type="email"],
  .FormBuilder textarea.is-invalid:focus,
  .FormBuilder select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid,
.was-validated .FormBuilder textarea:invalid, .FormBuilder .was-validated textarea:invalid, textarea.form-control.is-invalid,
.FormBuilder textarea.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid ~ .form-check-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid ~ .form-check-label, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid ~ .form-check-label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid ~ .form-check-label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input:invalid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated .form-check-input:invalid ~ label, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid ~ label,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input:invalid ~ label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated .form-check-input:invalid ~ label,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid ~ label, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid ~ label,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent .form-check-input:invalid ~ label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated .form-check-input:invalid ~ label,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid ~ label, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid ~ label, .form-check-input.is-invalid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid ~ .form-check-label, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid ~ .form-check-label, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid ~ .form-check-label, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-check-input.is-invalid ~ label, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid ~ label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent .form-check-input.is-invalid ~ label,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid ~ label,
.FormBuilder .InputfieldRadios .InputfieldContent .form-check-input.is-invalid ~ label,
.FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid ~ label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid ~ .invalid-feedback, .FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid ~ .invalid-feedback, .was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid ~ .invalid-feedback, .FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid ~ .invalid-feedback, .was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid ~ .invalid-feedback, .FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.was-validated .FormBuilder .InputfieldCheckbox .InputfieldContent input:invalid ~ .invalid-tooltip,
.FormBuilder .InputfieldCheckbox .InputfieldContent .was-validated input:invalid ~ .invalid-tooltip,
.was-validated .FormBuilder .InputfieldCheckboxes .InputfieldContent input:invalid ~ .invalid-tooltip,
.FormBuilder .InputfieldCheckboxes .InputfieldContent .was-validated input:invalid ~ .invalid-tooltip,
.was-validated .FormBuilder .InputfieldRadios .InputfieldContent input:invalid ~ .invalid-tooltip,
.FormBuilder .InputfieldRadios .InputfieldContent .was-validated input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid ~ .invalid-feedback, .FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid ~ .invalid-feedback, .FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.FormBuilder .InputfieldCheckbox .InputfieldContent input.is-invalid ~ .invalid-tooltip,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input.is-invalid ~ .invalid-tooltip,
.FormBuilder .InputfieldRadios .InputfieldContent input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .form-inline .FormBuilder .InputfieldCheckbox .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckbox .InputfieldContent .form-inline label:not(.custom-control-label),
  .form-inline .FormBuilder .InputfieldCheckboxes .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-inline label:not(.custom-control-label),
  .form-inline .FormBuilder .InputfieldRadios .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldRadios .InputfieldContent .form-inline label:not(.custom-control-label) {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .form-inline .FormBuilder .Inputfield, .FormBuilder .form-inline .Inputfield,
    .form-inline .FormBuilder fieldset, .FormBuilder .form-inline fieldset {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .FormBuilder input[type="text"], .FormBuilder .form-inline input[type="text"],
    .form-inline .FormBuilder input[type="email"], .FormBuilder .form-inline input[type="email"],
    .form-inline .FormBuilder textarea, .FormBuilder .form-inline textarea,
    .form-inline .FormBuilder select, .FormBuilder .form-inline select {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check, .form-inline .FormBuilder .InputfieldCheckbox .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckbox .InputfieldContent .form-inline label:not(.custom-control-label),
    .form-inline .FormBuilder .InputfieldCheckboxes .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-inline label:not(.custom-control-label),
    .form-inline .FormBuilder .InputfieldRadios .InputfieldContent label:not(.custom-control-label), .FormBuilder .InputfieldRadios .InputfieldContent .form-inline label:not(.custom-control-label) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .form-inline .FormBuilder .InputfieldCheckbox .InputfieldContent input, .FormBuilder .InputfieldCheckbox .InputfieldContent .form-inline input,
    .form-inline .FormBuilder .InputfieldCheckboxes .InputfieldContent input, .FormBuilder .InputfieldCheckboxes .InputfieldContent .form-inline input,
    .form-inline .FormBuilder .InputfieldRadios .InputfieldContent input, .FormBuilder .InputfieldRadios .InputfieldContent .form-inline input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #111;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1640px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1920px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

@media (min-width: 2400px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 3px #c7cec5; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #c7cec5; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.3rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #c7cec5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #c7cec5;
    box-shadow: 0 0 3px #c7cec5; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.6;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 3px #c7cec5; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 3px #c7cec5; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 3px #c7cec5; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #111; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1640px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2400px) {
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* stylelint-disable-line scss/dollar-variable-pattern */
:root {
  --swiper-theme-color: #000; }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* CSS Mode */
.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */ }
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none; }

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start; }

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory; }

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory; }

:root {
  /*
  --swiper-pagination-color: var(--swiper-theme-color);
  */ }

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10; }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }
  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color, var(--swiper-theme-color)); }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block; }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
      display: inline-block;
      transition: 200ms transform, 200ms top; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms left; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right; }

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top; }
  .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top; }
  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0; }
  .swiper-container-vertical > .swiper-pagination-progressbar,
  .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0; }

.swiper-pagination-gray-96 {
  --swiper-pagination-color: whitesmoke; }

.swiper-pagination-gray-68 {
  --swiper-pagination-color: #adadad; }

.swiper-pagination-gray-54 {
  --swiper-pagination-color: #8a8a8a; }

.swiper-pagination-gray-30 {
  --swiper-pagination-color: #4d4d4d; }

.swiper-pagination-green-1 {
  --swiper-pagination-color: #c7cec5; }

.swiper-pagination-black-18 {
  --swiper-pagination-color: #312f2b; }

.swiper-pagination-black {
  --swiper-pagination-color: black; }

.swiper-pagination-brown {
  --swiper-pagination-color: #302E2A; }

.swiper-pagination-lock {
  display: none; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity; }
  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }
  .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%; }
  .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-scrollbar-lock {
  display: none; }

@font-face {
  font-family: "Optima";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/optima-regular.woff2") format("woff2"), url("../fonts/optima-regular.woff") format("woff"), url("../fonts/optima-regular.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/SailecThin/font.woff2") format("woff2"), url("../fonts/SailecThin/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/SailecThinItalic/font.woff2") format("woff2"), url("../fonts/SailecThinItalic/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/SailecLight/font.woff2") format("woff2"), url("../fonts/SailecLight/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/SailecLightItalic/font.woff2") format("woff2"), url("../fonts/SailecLightItalic/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/SailecRegular/font.woff2") format("woff2"), url("../fonts/SailecRegular/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/SailecRegularItalic/font.woff2") format("woff2"), url("../fonts/SailecRegularItalic/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SailecMedium/font.woff2") format("woff2"), url("../fonts/SailecMedium/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/SailecMediumItalic/font.woff2") format("woff2"), url("../fonts/SailecMediumItalic/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/SailecBold/font.woff2") format("woff2"), url("../fonts/SailecBold/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/SailecBoldItalic/font.woff2") format("woff2"), url("../fonts/SailecBoldItalic/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/SailecBlack/font.woff2") format("woff2"), url("../fonts/SailecBlack/font.woff") format("woff"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/SailecBlackItalic/font.woff2") format("woff2"), url("../fonts/SailecBlackItalic/font.woff") format("woff"); }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px ; }
  @media (min-width: 992px) {
    html {
      font-size: 16px ; } }
  @media (min-width: 1640px) {
    html {
      font-size: 18px ; } }

body {
  overscroll-behavior-y: none;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  body .Main {
    flex-grow: 2; }

[data-accordion-toggle] {
  outline: none !important; }

svg {
  overflow: visible; }

figure {
  margin: 0; }

img {
  width: 100%;
  max-width: 100%; }

a {
  color: #000; }
  a:hover {
    text-decoration: none;
    color: #000; }
  a[href^="tel:"] {
    text-decoration: none !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Optima", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0.5rem; }

.h1-large {
  font-size: 37.46508px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    .h1-large {
      font-size: calc( 37.46508px + 22.5601 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    .h1-large {
      font-size: 60.02518px; } }

h1,
.h1 {
  font-size: 34px;
  line-height: 1.2; }
  @media (min-width: 320px) and (max-width: 1440px) {
    h1,
    .h1 {
      font-size: calc( 34px + 18 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    h1,
    .h1 {
      font-size: 52px; } }

.h1-small {
  font-size: 23.51351px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    .h1-small {
      font-size: calc( 23.51351px + 6.62688 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    .h1-small {
      font-size: 30.14039px; } }

h2,
.h2 {
  font-size: 31.28616px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    h2,
    .h2 {
      font-size: calc( 31.28616px + 14.69475 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    h2,
    .h2 {
      font-size: 45.98091px; } }

h3,
.h3 {
  font-size: 29.43469px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    h3,
    .h3 {
      font-size: calc( 29.43469px + 12.57976 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    h3,
    .h3 {
      font-size: 42.01444px; } }

h4,
.h4 {
  font-size: 26.49104px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    h4,
    .h4 {
      font-size: calc( 26.49104px + 9.4612 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    h4,
    .h4 {
      font-size: 35.95224px; } }

h5,
.h5 {
  font-size: 23.51351px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    h5,
    .h5 {
      font-size: calc( 23.51351px + 6.62688 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    h5,
    .h5 {
      font-size: 30.14039px; } }

h6,
.h6 {
  font-size: 20.35626px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    h6,
    .h6 {
      font-size: calc( 20.35626px + 3.99628 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    h6,
    .h6 {
      font-size: 24.35254px; } }

.sub-heading {
  font-size: 1.375rem;
  font-weight: 500;
  margin-bottom: 2rem;
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.p-xlarge {
  font-size: 1.375rem; }

.p-large {
  font-size: 20px; }

.p-small {
  font-size: 14px; }

.p-xsmall {
  font-size: 12px; }

.strong {
  font-weight: 700; }

.p-label {
  color: #4d4d4d; }

.text-muted {
  color: #7F7E7B; }

.Accordion ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.ArcDetail .Accordion {
  margin: 2.5rem 0;
  padding: 0; }
  .ArcDetail .Accordion li:first-child {
    border-top: 1px solid rgba(151, 151, 151, 0.3); }

.Accordion-listItem {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3); }

.ArcDetail .Accordion-row {
  margin: 0; }

@media (min-width: 768px) {
  .Accordion-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

.ArcDetail .Accordion-col {
  padding: 0;
  max-width: 100%;
  flex: 100%; }

.Accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0; }

.Accordion-headerTitle {
  margin: 0; }

.Accordion-headerButtonWrapper {
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
  border-radius: 50%; }

.Accordion-headerButton {
  transform: rotate(90deg);
  position: relative;
  transition: transform 0.6s;
  height: 1.5rem;
  width: 1.5rem; }
  .Accordion-headerButton::after, .Accordion-headerButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s;
    transform: rotate(90deg); }
  .Accordion-headerButton::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %234a4a4a; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3Cline x1='8' y1='13' x2='8' y2='3'/%3E%3C/svg%3E") center/contain no-repeat; }
  .Accordion-headerButton::after {
    opacity: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %234a4a4a; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3C/svg%3E") center/contain no-repeat; }
  .is-open .Accordion-headerButton {
    transform: rotate(270deg); }
    .is-open .Accordion-headerButton:before {
      opacity: 0; }
    .is-open .Accordion-headerButton:after {
      opacity: 1; }

.Accordion-bodyContent {
  padding: 1.5rem 0 2.5rem; }

.alert-error {
  background: #ca1616;
  color: #fff; }

.alert-success {
  background: #199509;
  color: #fff; }

@media (min-width: 768px) {
  .ArcDetail-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 992px) {
  .ArcDetail-col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.ArcDetail .sub-heading {
  font-weight: 700;
  font-size: 1rem; }

.ArcItemList {
  padding-top: 5rem;
  padding-bottom: 8.53333vw ;
  background: #312f2b;
  color: #fff; }
  @media (min-width: 768px) {
    .ArcItemList {
      padding-bottom: 4.44444vw ; } }
  .ArcItemList a {
    color: #fff; }
  .ArcItemList ul {
    list-style: none;
    padding: 0;
    margin: 0; }

@media (min-width: 768px) {
  .ArcItemList-headerCol,
  .ArcItemList-mediaCol {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 768px) {
  .ArcItemList-contentCol {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 992px) {
  .ArcItemList-contentCol {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin-left: auto;
    margin-right: auto; } }

.ArcItemList-caption {
  margin-bottom: 0.25rem; }

.ArcItemList-header {
  margin-bottom: 2.5rem; }

.ArcItemList-contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; }

.ArcItemList-headerButtons {
  display: flex; }

.ArcItemList-itemButton {
  padding: 0.3rem 0.75rem; }
  .ArcItemList-itemButton:not(:last-child) {
    margin-right: 0.25rem; }
  .ArcItemList-itemButton.is-active {
    border: 1px solid #C8CFC6;
    border-radius: 7px;
    margin-top: -1px; }
    .ArcItemList-itemButton.is-active:last-child {
      margin-right: -1px; }

.ArcItemList-contentList {
  border-top: 1px solid #fff; }

.ArcItemList-listItem {
  overflow: hidden; }
  .ArcItemList-listItem:not(:last-child) {
    border-bottom: 1px solid rgba(151, 151, 151, 0.3); }

.ArcItemList-itemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  margin-left: -2.4rem;
  transition: transform 0.3s; }
  .ArcItemList-itemLink:hover {
    transform: translateX(2.4rem); }

.ArcItemList-itemSize span {
  width: 1.3rem;
  margin-right: 1rem; }

.ArcItemList-sizeMm,
.ArcItemList-sizeInch {
  display: none; }

.show-mm .ArcItemList-sizeMm {
  display: inline; }

.show-inch .ArcItemList-sizeInch {
  display: inline; }

.ArcItemList-figure {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
  background: #c7cec5; }
  @media (max-width: 767.98px) {
    .ArcItemList-figure {
      margin-bottom: 3rem; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .ArcItemList-figure {
      margin-bottom: 5.55556vw ; } }

.ArcItemList-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .ArcItemList-image.lazyloaded {
    opacity: 1; }

.Banner {
  display: flex;
  flex-wrap: wrap; }
  .Banner .Thumbnail {
    margin-bottom: 0; }
    .Banner .Thumbnail:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
      transform: translateZ(0); }

.Banner-wrapper {
  flex: 1 0 100%;
  position: relative;
  border-bottom: 1px solid white; }
  @media (min-width: 768px) {
    .Banner-wrapper {
      flex: 1 0 50%; }
      .Banner-wrapper:first-child .Thumbnail {
        border-right: 1px solid white; } }

.Banner-title {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 4.26667vw ;
  padding-top: 4.26667vw ;
  z-index: 1;
  color: #fff; }
  @media (min-width: 768px) {
    .Banner-title {
      padding-left: 2.22222vw ;
      padding-top: 2.22222vw ; } }

.BlockUI {
  pointer-events: none;
  opacity: 0.4; }

.Button, .FormBuilder button:not(.dropdown-toggle) {
  position: relative;
  border: 2px solid #B6C5B1;
  border-radius: 10px;
  padding: 0.75rem 1rem;
  min-width: 12.5rem;
  background: #fff;
  color: #000; }
  .Button::before, .FormBuilder button:not(.dropdown-toggle)::before, .Button::after, .FormBuilder button:not(.dropdown-toggle)::after {
    content: "";
    height: 1.8rem;
    transition: transform 0.5s;
    background: #fff;
    top: 50%;
    position: absolute;
    width: 6px; }
  .Button::before, .FormBuilder button:not(.dropdown-toggle)::before {
    left: 0;
    transform: translate(-50%, -50%) scaleY(0.99);
    transform-origin: center top; }
  .Button::after, .FormBuilder button:not(.dropdown-toggle)::after {
    right: 0;
    transform: translate(50%, -50%) scaleY(0.99);
    transform-origin: center bottom; }
  .Button:hover::before, .FormBuilder button:not(.dropdown-toggle):hover::before {
    transform: translate(-50%, -50%) scaleY(0); }
  .Button:hover::after, .FormBuilder button:not(.dropdown-toggle):hover::after {
    transform: translate(50%, -50%) scaleY(0); }

.CatalogueList + .CatalogueList {
  margin-top: 1.5rem; }

.CatalogueList-col {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 768px) {
    .CatalogueList-col {
      flex: 0 0 25%;
      max-width: 25%; } }

.CatalogueList-row {
  margin-top: -3rem; }
  @media (min-width: 768px) {
    .CatalogueList-row {
      margin-top: -5.55556vw ; } }

.CatalogueList-col {
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .CatalogueList-col {
      margin-top: 5.55556vw ; } }

.CataloguePreview-figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 138%;
  background: #c7cec5;
  margin-bottom: 1rem; }

.CataloguePreview-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .CataloguePreview-image.lazyloaded {
    opacity: 1; }

.CataloguePreview-title {
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0; }

.CollectionFilter {
  color: #45433f; }
  .CollectionFilter ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  @media (max-width: 767.98px) {
    .CollectionFilter {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1010; } }
  html:not(.show-modal) .CollectionFilter {
    pointer-events: none; }

@media (max-width: 767.98px) {
  .CollectionFilter-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s opacity; } }

html:not(.show-modal) .CollectionFilter-bg {
  opacity: 0; }

@media (max-width: 767.98px) {
  .CollectionFilter-modal {
    padding-top: 60px ;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: transform 0.6s;
    left: 15%;
    flex-direction: column;
    background-color: #c7cec5; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .CollectionFilter-modal {
      padding-top: 80px ; } }

@media (max-width: 767.98px) {
    html:not(.show-modal) .CollectionFilter-modal {
      transform: translateX(110%); } }

.CollectionFilter-header {
  height: 60px ;
  padding-right: 4.26667vw ;
  padding-left: 4.26667vw ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #fff; }
  @media (min-width: 768px) {
    .CollectionFilter-header {
      height: 80px ; } }
  @media (min-width: 768px) {
    .CollectionFilter-header {
      padding-right: 2.22222vw ;
      padding-left: 2.22222vw ; } }
  .CollectionFilter-header .Icon {
    margin-left: 0.25rem; }

.CollectionFilter-close {
  pointer-events: auto;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  color: #45433f;
  outline: none !important;
  transform: scale(0.7); }
  .CollectionFilter-close .Icon {
    stroke-width: 2.08px; }

@media (min-width: 768px) {
  .CollectionFilter-contentBg {
    background-color: #c7cec5;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleY(99.9%);
    transition: 0.5s transform cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: center top; }
    html:not(.show-modal) .CollectionFilter-contentBg {
      transform: scaleY(0); } }

@media (min-width: 768px) {
  .CollectionFilter-content {
    z-index: 1000;
    position: absolute;
    width: 100%;
    display: flex;
    height: unset !important; } }

@media (max-width: 767.98px) {
  .CollectionFilter-content {
    overflow-y: auto; } }

@media (max-width: 767.98px) {
  .CollectionFilter-section {
    border-bottom: 1px solid #fff; } }

@media (min-width: 768px) {
  .CollectionFilter-section {
    padding-right: 4.26667vw ;
    padding-left: 4.26667vw ;
    padding-top: 4.26667vw ;
    padding-bottom: 5rem;
    flex: 1;
    position: relative; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .CollectionFilter-section {
      padding-right: 2.22222vw ;
      padding-left: 2.22222vw ;
      padding-top: 2.22222vw ; } }

@media (min-width: 768px) {
    .CollectionFilter-section:not(:last-child)::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: #fff;
      transition: transform 0.3s;
      transform: scaleY(99.9%);
      transition: 0.5s transform cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: center top; }
      html:not(.show-modal) .CollectionFilter-section:not(:last-child)::before {
        transform: scaleY(0); }
    .CollectionFilter-section span,
    .CollectionFilter-section ul {
      display: inline-block;
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.25s;
      transition-property: transform, opacity;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
      html:not(.show-modal) .CollectionFilter-section span, html:not(.show-modal)
      .CollectionFilter-section ul {
        transition-delay: 0.75s, 0s;
        transform: translateY(2rem);
        opacity: 0; } }

.CollectionFilter-sectionHeader {
  font-weight: 500;
  padding-bottom: 1.5rem; }
  @media (max-width: 767.98px) {
    .CollectionFilter-sectionHeader {
      padding-right: 4.26667vw ;
      padding-left: 4.26667vw ;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.5rem; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .CollectionFilter-sectionHeader {
      padding-right: 2.22222vw ;
      padding-left: 2.22222vw ; } }
  @media (min-width: 768px) {
    .CollectionFilter-sectionHeader {
      pointer-events: none; } }

@media (max-width: 767.98px) {
  .CollectionFilter-sectionHeaderClose {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    transition: transform 0.6s; }
    .CollectionFilter-sectionHeaderClose::after, .CollectionFilter-sectionHeaderClose::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: opacity 0.3s; }
    .CollectionFilter-sectionHeaderClose::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %234a4a4a; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3Cline x1='8' y1='13' x2='8' y2='3'/%3E%3C/svg%3E") center/contain no-repeat; }
    .CollectionFilter-sectionHeaderClose::after {
      opacity: 0;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %234a4a4a; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3C/svg%3E") center/contain no-repeat; }
    .is-open .CollectionFilter-sectionHeaderClose {
      transform: rotate(180deg); }
      .is-open .CollectionFilter-sectionHeaderClose:before {
        opacity: 0; }
      .is-open .CollectionFilter-sectionHeaderClose:after {
        opacity: 1; } }

.CollectionFilter-sectionContent li {
  font-size: 14px; }
  @media (min-width: 1440px) {
    .CollectionFilter-sectionContent li {
      font-size: 1rem; } }

.CollectionFilter-sectionContent a {
  color: #45433f;
  position: relative;
  display: inline-flex;
  align-items: center;
  opacity: 0.8; }

.CollectionFilter-sectionContent a.is-disabled {
  opacity: 0.4;
  pointer-events: none; }

.CollectionFilter-sectionContent a.is-active:before {
  content: '';
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: -1.25rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %2345433f; stroke-width: 1.3px'%3E%3Cpolyline points='2.5,9 5.5,12 13.5,4' /%3E%3C/svg%3E") center/contain no-repeat; }

@media (max-width: 767.98px) {
  .CollectionFilter-sectionContent {
    padding-right: 4.26667vw ;
    padding-left: 4.26667vw ; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .CollectionFilter-sectionContent {
      padding-right: 2.22222vw ;
      padding-left: 2.22222vw ; } }

@media (max-width: 767.98px) {
    .CollectionFilter-sectionContent li {
      padding-left: 1.25rem; }
    .CollectionFilter-sectionContent a {
      padding-bottom: 0.5rem; }
    .CollectionFilter-sectionContent a.is-active:before {
      left: -1.25rem; }
    .CollectionFilter-sectionContent li:last-child a {
      padding-bottom: 1.5rem; } }

@media (min-width: 768px) {
  .CollectionFilter-sectionContent li a {
    transition: transform 0.3s; }
  .CollectionFilter-sectionContent.collapse {
    display: block;
    height: auto !important;
    visibility: visible; }
  .CollectionFilter-sectionContent.collapsing {
    position: relative;
    height: unset !important;
    overflow: hidden; } }

.CollectionFiltered ul {
  margin-right: -1.5rem;
  list-style: none;
  margin: 0;
  padding: 0; }

.CollectionFiltered li {
  display: inline-block;
  border-radius: 7px;
  border: 1px solid #000;
  margin-right: -5px;
  margin-top: 4.26667vw ;
  margin-right: 1rem; }
  @media (min-width: 768px) {
    .CollectionFiltered li {
      margin-top: 2.22222vw ; } }
  .CollectionFiltered li a {
    font-size: 12px;
    padding: 0.35em 0.75em;
    display: inline-block; }

.CollectionFiltered .Icon {
  transform: scale(0.65);
  stroke-width: 1.82px;
  margin-left: 0.75rem; }

@media (min-width: 768px) {
  .CollectionList-col {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 1920px) {
  .CollectionList-col {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.CollectionList-row {
  margin-top: -3rem; }
  @media (min-width: 768px) {
    .CollectionList-row {
      margin-top: -5.55556vw ; } }

.CollectionList-col {
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .CollectionList-col {
      margin-top: 5.55556vw ; } }

@media (min-width: 768px) {
  .CollectionList-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    cursor: pointer;
    z-index: 10; } }

html:not(.show-modal) .CollectionList-bg {
  pointer-events: none;
  opacity: 0; }

.CollectionNav {
  background-color: #c7cec5;
  margin-top: -3.6rem;
  color: #312f2b; }

.CollectionNav {
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  cursor: pointer; }

.CollectionNav-heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  position: relative; }

.CollectionNav-headingFilter .Icon {
  margin-left: 0.25rem; }

.CollectionNav-headingCounter {
  color: rgba(48, 46, 42, 0.5); }

.CollectionPreview-title {
  margin: 0;
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #45433f; }

.CollectionRelated {
  overflow: hidden;
  padding-bottom: 1rem; }
  .CollectionRelated .swiper-wrapper {
    width: 100%; }
  .CollectionRelated .swiper-container {
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    margin-right: -2.13333vw;
    margin-left: -2.13333vw; }
    @media (min-width: 768px) {
      .CollectionRelated .swiper-container {
        margin-right: -1.11111vw;
        margin-left: -1.11111vw; } }
  .CollectionRelated .swiper-slide {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.13333vw;
    padding-left: 2.13333vw;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .CollectionRelated .swiper-slide {
        padding-right: 1.11111vw;
        padding-left: 1.11111vw; } }
    @media (min-width: 768px) {
      .CollectionRelated .swiper-slide {
        flex: 0 0 50%;
        max-width: 50%; } }
  .CollectionRelated .swiper-button-disabled {
    opacity: 0.3; }
  .CollectionRelated .swiper-pagination {
    text-align: center; }
    @media (min-width: 768px) {
      .CollectionRelated .swiper-pagination {
        display: none; } }
  .CollectionRelated .swiper-pagination-bullet:before {
    border-color: #000; }
  .CollectionRelated .swiper-pagination-bullet:after {
    background: #000; }
  @media (max-width: 767.98px) {
    .CollectionRelated .CollectionPreview {
      text-align: center; } }

.CollectionRelated-header {
  margin-bottom: 2.5rem; }
  @media (min-width: 768px) {
    .CollectionRelated-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; } }
  @media (max-width: 767.98px) {
    .CollectionRelated-header {
      text-align: center; } }

.CollectionRelated-headerTitle {
  margin: 0; }

.CollectionRelated-headerArrows {
  position: relative;
  display: flex;
  align-items: center; }
  @media (max-width: 767.98px) {
    .CollectionRelated-headerArrows {
      display: none; } }

.CollectionRelated-ArrowNext {
  width: 30px;
  text-align-last: right; }
  .CollectionRelated-ArrowNext span {
    transition: 0.2s transform; }
  .CollectionRelated-ArrowNext:hover span {
    transform: translate(20%); }

.CollectionRelated-ArrowPrev {
  width: 30px;
  text-align-last: left; }
  .CollectionRelated-ArrowPrev span {
    transition: 0.2s transform;
    transform: rotate(-180deg); }
  .CollectionRelated-ArrowPrev:hover span {
    transform: rotate(-180deg) translate(20%); }

.Cta {
  background: #c7cec5; }

.Cta-wrapper {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  padding-left: 4.26667vw ;
  padding-right: 4.26667vw ;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .Cta-wrapper {
      padding-top: 8.33333vw ;
      padding-bottom: 8.33333vw ; } }
  @media (min-width: 768px) {
    .Cta-wrapper {
      padding-left: 2.22222vw ;
      padding-right: 2.22222vw ; } }
  .Cta-wrapper::after {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.05);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    z-index: 2;
    transform-origin: 0 center; }
  .Cta-wrapper:hover::after {
    transform: scaleX(1); }

.Cta-Button {
  height: 4rem;
  width: 4rem;
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden; }

.Cta-ButtonArrowWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  transition-delay: 0s; }
  .Cta-wrapper:hover .Cta-ButtonArrowWrapper {
    transition-delay: 0.25s;
    transform: translateX(100%); }

.Cta-ButtonArrow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: transform 0.43s cubic-bezier(0.39, 0.575, 0.565, 1); }
  .Cta-ButtonArrow:first-child {
    transform: translate(calc(-150%), -50%); }

.CustomFilter ul {
  list-style: none;
  padding: 0;
  margin: 0; }

@media (max-width: 767.98px) {
  .CustomFilter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1010; } }

@media (max-width: 767.98px) {
  html:not(.show-modal) .CustomFilter {
    pointer-events: none; } }

@media (max-width: 767.98px) {
  .CustomFilter-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s opacity; } }

html:not(.show-modal) .CustomFilter-bg {
  opacity: 0; }

@media (max-width: 767.98px) {
  .CustomFilter-modal {
    padding-top: 60px ;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: transform 0.6s;
    left: 15%;
    flex-direction: column;
    background-color: #fff; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .CustomFilter-modal {
      padding-top: 80px ; } }

@media (max-width: 767.98px) {
    html:not(.show-modal) .CustomFilter-modal {
      transform: translateX(110%); } }

.CustomFilter-header {
  height: 60px ;
  padding-right: 4.26667vw ;
  padding-left: 4.26667vw ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  @media (min-width: 768px) {
    .CustomFilter-header {
      height: 80px ; } }
  @media (min-width: 768px) {
    .CustomFilter-header {
      padding-right: 2.22222vw ;
      padding-left: 2.22222vw ; } }
  .CustomFilter-header .Icon {
    margin-left: 0.25rem; }
  @media (min-width: 768px) {
    .CustomFilter-header {
      display: none; } }

.CustomFilter-list {
  overflow-y: auto; }
  @media (min-width: 768px) {
    .CustomFilter-list {
      height: auto !important; } }

.CustomFilter-listItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  @media (max-width: 767.98px) {
    .CustomFilter-listItem {
      padding-right: 4.26667vw ;
      padding-left: 4.26667vw ; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .CustomFilter-listItem {
      padding-right: 2.22222vw ;
      padding-left: 2.22222vw ; } }
  @media (min-width: 768px) {
    .CustomFilter-listItem:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); } }

.CustomFilter-listHeader {
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 767.98px) {
    .CustomFilter-listHeader {
      padding: 1.5rem 0;
      font-weight: 500; } }

.CustomFilter-listCloseButton {
  height: 1.4em;
  width: 1.4rem;
  position: relative;
  transition: transform 0.6s;
  transform: rotate(0.1deg); }
  .CustomFilter-listCloseButton::before, .CustomFilter-listCloseButton::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s; }
  .CustomFilter-listCloseButton::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %234A4A4A; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3Cline x1='8' y1='13' x2='8' y2='3'/%3E%3C/svg%3E") center/contain no-repeat; }
  .CustomFilter-listCloseButton::after {
    opacity: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %234A4A4A; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3C/svg%3E") center/contain no-repeat; }
  .is-open .CustomFilter-listCloseButton {
    transform: rotate(180deg); }
    .is-open .CustomFilter-listCloseButton:before {
      opacity: 0; }
    .is-open .CustomFilter-listCloseButton:after {
      opacity: 1; }

.CustomFilter-listBody li {
  padding-left: 1.25rem; }

.CustomFilter-listBodyItem {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  position: relative;
  opacity: 1; }
  @media (min-width: 1440px) {
    .CustomFilter-listBodyItem {
      font-size: 1rem; } }
  .CustomFilter-listBodyItem.is-disabled {
    pointer-events: none;
    opacity: 0.4; }
  .CustomFilter-listBodyItem.is-active::before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: -1.25rem;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %2345433f; stroke-width: 1.3px'%3E%3Cpolyline points='2.5,9 5.5,12 13.5,4' /%3E%3C/svg%3E") center/contain no-repeat; }
  li li:last-child .CustomFilter-listBodyItem {
    padding-bottom: 1.5rem; }
    @media (min-width: 768px) {
      li li:last-child .CustomFilter-listBodyItem {
        padding-bottom: 1.25rem; } }
  li li .CustomFilter-listBodyItem {
    padding-bottom: 0.5em; }

.CustomizationList {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .CustomizationList ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .Accordion .CustomizationList {
    margin-top: -2.5rem; }

.CustomizationList-title {
  font-weight: 700;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .CustomizationList-title {
      margin-bottom: 2.77778vw ; } }

.CustomizationList-row {
  margin-top: -2.5rem; }

.CustomizationList-col {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 2.5rem; }
  @media (min-width: 768px) {
    .CustomizationList-col {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.CustomizationList-label {
  opacity: 0.6;
  margin-top: 0.25rem;
  margin-bottom: 0; }

.CustomizationList-figure {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  background: #c7cec5; }

.CustomizationList-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .CustomizationList-image.lazyloaded {
    opacity: 1; }

.CustomList {
  margin-top: 1rem;
  margin-bottom: 6rem;
  color: #45433f; }
  @media (min-width: 768px) {
    .CustomList {
      margin-bottom: 11.11111vw ; } }
  .CustomList a {
    color: #45433f; }

@media (min-width: 768px) {
  .--hasFilter .CustomList-filterCol {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .--hasFilter .CustomList-filterCol {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 768px) {
  .CustomList-filterColWrapper {
    top: 60px ;
    position: sticky;
    height: auto; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .CustomList-filterColWrapper {
      top: 80px ; } }

.CustomList-filterHeader,
.CustomList-listHeader {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .CustomList-filterHeader,
    .CustomList-listHeader {
      height: 5rem; } }

.CustomList:not(.--hasFilter) .CustomList-listHeader {
  display: none; }

.CustomList-listSeoToggle {
  line-height: 1;
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  cursor: pointer; }
  .CustomList-listSeoToggle::before {
    content: "?";
    font-size: 13px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.CustomList-listSeoText span {
  display: block;
  padding-bottom: 1rem; }

@media (max-width: 767.98px) {
  .CustomList-filterHeader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); } }

.CustomList-filterHeaderButton {
  cursor: pointer; }
  @media (min-width: 768px) {
    .CustomList-filterHeaderButton {
      pointer-events: none; } }

.CustomList-filterHeaderDelete {
  display: none;
  z-index: 1; }
  .CustomList-filterHeaderDelete.is-active {
    display: block; }

.CustomList-listCol {
  margin-bottom: -3rem; }
  @media (min-width: 768px) {
    .CustomList-listCol {
      margin-bottom: -5.55556vw ; } }
  @media (min-width: 768px) {
    .--hasFilter .CustomList-listCol {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }
  @media (min-width: 992px) {
    .--hasFilter .CustomList-listCol {
      flex: 0 0 75%;
      max-width: 75%; } }

.CustomList:not(.--hasFilter) .CustomList-listRow {
  margin-top: 5rem; }
  @media (max-width: 767.98px) {
    .CustomList:not(.--hasFilter) .CustomList-listRow {
      margin-top: 4.26667vw ; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .CustomList:not(.--hasFilter) .CustomList-listRow {
      margin-top: 2.22222vw ; } }

.CustomList-listInnerCol {
  margin-bottom: 3rem; }
  @media (min-width: 768px) {
    .CustomList-listInnerCol {
      margin-bottom: 5.55556vw ; } }
  @media (min-width: 768px) {
    .CustomList-listInnerCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .CustomList-listInnerCol {
      flex: 0 0 25%;
      max-width: 25%; } }
  @media (min-width: 768px) {
    .--hasFilter .CustomList-listInnerCol {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .--hasFilter .CustomList-listInnerCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.CustomNav {
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  background-color: #c7cec5;
  margin-top: -3.6rem;
  position: relative; }
  .CustomNav a {
    color: #312f2b; }
  .CustomNav ul {
    list-style: none;
    margin: 0; }
  .CustomNav::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 4.26667vw ;
    left: unset;
    background-image: linear-gradient(to left, #c7cec5, #c7cec5 20%, rgba(199, 206, 197, 0) 100%);
    pointer-events: none; }
    @media (min-width: 768px) {
      .CustomNav::after {
        width: 2.22222vw ; } }
  .CustomNav::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 4.26667vw ;
    z-index: 1;
    right: unset;
    background-image: linear-gradient(to right, #c7cec5, #c7cec5 20%, rgba(199, 206, 197, 0) 100%);
    pointer-events: none; }
    @media (min-width: 768px) {
      .CustomNav::before {
        width: 2.22222vw ; } }

.CustomNav-list {
  padding-left: 4.26667vw ;
  display: flex;
  position: relative;
  white-space: nowrap;
  overflow-x: auto; }
  @media (min-width: 768px) {
    .CustomNav-list {
      padding-left: 2.22222vw ; } }
  .CustomNav-list::-webkit-scrollbar {
    display: none; }

.CustomNav-listItem a {
  display: inline-block;
  padding: 1rem 0;
  margin-right: 1.5rem; }
  .CustomNav-listItem a.is-active {
    text-decoration: underline; }
  @media (min-width: 768px) {
    .CustomNav-listItem a {
      margin-right: 2rem; } }

.CustomNav-listItem:last-child a {
  padding-right: 4.26667vw ;
  margin-right: 0; }
  @media (min-width: 768px) {
    .CustomNav-listItem:last-child a {
      padding-right: 2.22222vw ; } }

.CustomNav-headingCounter {
  opacity: 0.4; }

.CustomPreview .Thumbnail {
  padding-bottom: 100%; }

.CustomPreview-title,
.CustomPreview-subtitle {
  margin: 0; }

.CustomPreview-title {
  color: #45433f; }

.CustomPreview-subtitle {
  color: rgba(48, 46, 42, 0.5); }

.DistributorList ul {
  list-style: none;
  padding: 0; }

.DistributorList p {
  margin: 0; }

.DistributorList a {
  display: block; }
  .DistributorList a:hover {
    text-decoration: underline !important;
    cursor: pointer; }

@media (min-width: 992px) {
  .DistributorList-mainCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

.DistributorList-row {
  margin-top: -3rem; }
  @media (min-width: 768px) {
    .DistributorList-row {
      margin-top: -5.55556vw ; } }

.DistributorList-col {
  margin-top: 3rem; }
  .DistributorList-col:first-child {
    margin-right: 100%; }
  @media (min-width: 768px) {
    .DistributorList-col {
      margin-top: 5.55556vw ; } }
  @media (min-width: 768px) {
    .DistributorList-col {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1440px) {
    .DistributorList-col {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 1920px) {
    .DistributorList-col {
      flex: 0 0 25%;
      max-width: 25%; } }

.DistributorList-label {
  font-weight: 500;
  border-bottom: 1px solid #979797;
  padding-bottom: 0.4rem; }

.DistributorList-place {
  margin: 1rem 0 1.5rem 0; }

.DistributorList-placeTitle {
  margin-bottom: 0.5rem; }

.DistributorList-placeAddress,
.DistributorList-placeTelephone,
.DistributorList-agentName,
.DistributorList-agentTelephone,
.DistributorList-description,
.DistributorList-agentEmail {
  color: rgba(0, 0, 0, 0.6); }

.DistributorList-description {
  padding-top: 0.75rem; }

.DistributorList-agent + .DistributorList-agent {
  margin-top: 0.75rem; }

.DistributorList-agentName {
  font-weight: 700; }

.DownloadList {
  margin-top: 2.5rem; }
  .DownloadList ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .DownloadList + .DownloadList {
    margin-top: 1.5rem; }
  .Accordion .DownloadList:first-child {
    margin-top: 0; }

.DownloadList-listItem:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.2); }

.DownloadList-listItem:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.DownloadList-itemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0; }
  .DownloadList-itemLink span:first-child {
    opacity: 0.6; }
  .DownloadList-itemLink:hover span:last-child {
    text-decoration: underline; }

.fancybox-slide--html .fancybox-close-small {
  background: #fff;
  border-radius: 50%;
  margin: 7.5px;
  padding: 5px;
  height: 30px;
  width: 30px; }

.fancybox-button {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.fancybox-iconClose {
  position: relative;
  flex-shrink: 0;
  display: block;
  height: 1.5rem;
  width: 1.5rem; }
  .fancybox-iconClose::after, .fancybox-iconClose::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s;
    transform: rotate(45deg); }
  .fancybox-iconClose::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %23fff; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3Cline x1='8' y1='13' x2='8' y2='3'/%3E%3C/svg%3E") center/contain no-repeat; }
    .fancybox-hotspots .fancybox-iconClose::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 16' style='fill: none; stroke: %23000; stroke-width: 1px'%3E%3Cline x1='3' y1='8' x2='13' y2='8'/%3E%3Cline x1='8' y1='13' x2='8' y2='3'/%3E%3C/svg%3E") center/contain no-repeat; }

.fancybox--overlay .fancybox-slide--html {
  padding: 0; }

.fancybox--overlay .fancybox-content {
  padding: 0;
  width: 100%; }

.fancybox--overlay .fancybox-toolbar {
  height: 60px ; }
  @media (min-width: 768px) {
    .fancybox--overlay .fancybox-toolbar {
      height: 80px ; } }

.fancybox--overlay .fancybox-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: #312f2b;
  border-radius: 50%;
  margin-right: 2.13333vw ; }
  @media (min-width: 768px) {
    .fancybox--overlay .fancybox-button {
      margin-right: 1.11111vw ; } }
  .fancybox--overlay .fancybox-button svg {
    color: #45433f; }

.fancybox--overlay .fancybox-loading {
  border: 2px solid #888;
  border-bottom-color: #fff; }

.FeatureGallery {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .FeatureGallery {
      margin-top: 11.11111vw ;
      margin-bottom: 11.11111vw ; } }
  .FeatureGallery.--background {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: whitesmoke; }
    @media (min-width: 768px) {
      .FeatureGallery.--background {
        padding-top: 5.55556vw ;
        padding-bottom: 5.55556vw ; } }
  .FeatureGallery ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .FeatureGallery .tab-content {
    width: 100%; }
  .FeatureGallery .tab-pane {
    width: 100%;
    display: block;
    opacity: 0;
    transition: opacity 0.25s; }
    .FeatureGallery .tab-pane.active {
      opacity: 1; }
  .FeatureGallery .nav {
    margin-bottom: 3rem;
    justify-content: center;
    margin-top: -1rem; }
  .FeatureGallery .nav-item {
    margin: 1rem 0.1rem 0; }
    @media (min-width: 768px) {
      .FeatureGallery .nav-item {
        margin: 1rem 0.25rem 0; } }
  .FeatureGallery .tab-content {
    background: #c7cec5;
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    overflow: hidden; }

.nav-link {
  font-size: 14px;
  border-radius: 7px;
  border: 1px solid #fff;
  line-height: 1;
  padding: 0.65rem 0.8rem 0.5rem; }
  .nav-link:hover {
    border-color: #C8CFC6; }
  .nav-link.active {
    border: 1px solid #000; }
  .--background .nav-link {
    border-color: whitesmoke; }
  .--background .nav-link:hover {
    border-color: #C8CFC6; }
  .--background .nav-link.active {
    border: 1px solid #000; }

.FeatureGallery-col {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .FeatureGallery-col {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      margin-left: auto;
      margin-right: auto; }
      .--imageSmall .FeatureGallery-col {
        flex: 0 0 50%;
        max-width: 50%; } }

.FeatureGallery-title {
  margin-bottom: 2rem;
  text-align: center; }

.FeatureGallery-nav {
  display: flex; }

.FeatureGallery-figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.FeatureGallery-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .FeatureGallery-image.lazyloaded {
    opacity: 1; }

label a:hover {
  text-decoration: underline; }

.FormBuilder input[type="file"] {
  opacity: 0; }

.FormBuilder .InputfieldHidden {
  display: none; }

.FormBuilder .InputfieldStateError input[type="text"],
.FormBuilder .InputfieldStateError input[type="email"],
.FormBuilder .InputfieldStateError textarea,
.FormBuilder .InputfieldStateError select,
.FormBuilder .InputfieldStateError .dropdown-toggle {
  border-color: red; }
  .FormBuilder .InputfieldStateError input[type="text"]:focus,
  .FormBuilder .InputfieldStateError input[type="email"]:focus,
  .FormBuilder .InputfieldStateError textarea:focus,
  .FormBuilder .InputfieldStateError select:focus,
  .FormBuilder .InputfieldStateError .dropdown-toggle:focus {
    border-color: red;
    box-shadow: 0 0 0 1px red; }

.FormBuilder .input-error {
  font-size: 14px; }

.FormBuilder .notes {
  font-size: 14px;
  margin-bottom: 0; }

.FormBuilder .Inputfields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .FormBuilder .Inputfields {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }

.FormBuilder .Inputfield,
.FormBuilder fieldset {
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw; }
  @media (min-width: 768px) {
    .FormBuilder .Inputfield,
    .FormBuilder fieldset {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  @media (min-width: 768px) {
    .FormBuilder .Inputfield,
    .FormBuilder fieldset {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }
  @media (max-width: 767.98px) {
    .FormBuilder .Inputfield,
    .FormBuilder fieldset {
      width: 100% !important; } }

.FormBuilder .InputfieldStateRequired label.InputfieldHeader:after,
.FormBuilder .InputfieldStateRequired label .pw-no-select:after {
  content: ' *';
  font-size: 12px;
  color: inherit; }

.FormBuilder .InputfieldCheckbox .InputfieldHeader {
  display: none; }

.FormBuilder .InputfieldRadios .InputfieldContent input {
  margin-top: .25em; }

.FormBuilder .InputfieldCheckbox .InputfieldContent input,
.FormBuilder .InputfieldCheckboxes .InputfieldContent input {
  margin-top: .3em; }

.FormBuilder .Inputfield_file li + li {
  margin-top: 1rem; }

.FormBuilder .InputfieldContent ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.FormBuilder .InputfieldSubmit {
  margin: 1rem 0 2rem;
  display: flex;
  justify-content: center; }

.Gallery {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: whitesmoke; }
  @media (min-width: 768px) {
    .Gallery {
      padding-top: 11.11111vw ;
      padding-bottom: 11.11111vw ; } }
  .Gallery .swiper-slide {
    padding-bottom: 75%;
    height: auto; }
  @media (min-width: 768px) {
    .Gallery .swiper-pagination {
      display: none; } }
  .Gallery .swiper-pagination-bullet:before {
    border-color: #45433f; }
  .Gallery .swiper-pagination-bullet:after {
    background-color: #45433f; }
  .Gallery .swiper-button-disabled {
    opacity: 0.3; }
  @media (max-width: 767.98px) {
    .Gallery {
      padding-top: 3rem; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .Gallery {
      padding-top: 5.55556vw ; } }

.Gallery-mainCol {
  margin-left: auto;
  margin-right: auto;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .Gallery-mainCol {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.Gallery-title {
  text-align: center;
  margin-bottom: 2.5rem; }

.Gallery-galleryWrapper {
  position: relative; }
  @media (min-width: 768px) {
    .Gallery-galleryWrapper {
      margin-left: 5.97333vw ;
      margin-right: 5.97333vw ; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .Gallery-galleryWrapper {
      margin-left: 3.11111vw ;
      margin-right: 3.11111vw ; } }

.Gallery-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/contain no-repeat; }
  .Gallery-image.lazyloaded {
    opacity: 1; }

.Gallery-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .Gallery-video video {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.Gallery-next {
  right: -12.8vw ;
  color: #45433f;
  position: absolute;
  top: 50%;
  width: 30px;
  text-align: right;
  transform: translateY(-50%);
  outline: none; }
  @media (min-width: 768px) {
    .Gallery-next {
      right: -6.66667vw ; } }
  .Gallery-next span {
    transition: 0.2s transform; }
  .Gallery-next:hover span {
    transform: translate(20%); }
  @media (max-width: 767.98px) {
    .Gallery-next {
      display: none; } }

.Gallery-prev {
  left: -12.8vw ;
  color: #45433f;
  position: absolute;
  top: 50%;
  width: 30px;
  text-align: right;
  transform: translateY(-50%) rotate(-180deg);
  outline: none; }
  @media (min-width: 768px) {
    .Gallery-prev {
      left: -6.66667vw ; } }
  .Gallery-prev span {
    transition: 0.2s transform; }
  .Gallery-prev:hover span {
    transform: translate(20%); }
  @media (max-width: 767.98px) {
    .Gallery-prev {
      display: none; } }

.GalleryText-mobile {
  overflow-y: hidden; }
  .GalleryText-mobile .swiper-container {
    overflow: visible;
    margin-bottom: 2rem; }
  .GalleryText-mobile .swiper-slide {
    width: 90%; }
    .GalleryText-mobile .swiper-slide:not(:last-child) {
      margin-right: 4.26667vw ; }
      @media (min-width: 768px) {
        .GalleryText-mobile .swiper-slide:not(:last-child) {
          margin-right: 2.22222vw ; } }
    .GalleryText-mobile .swiper-slide:only-child {
      width: 100%; }
  @media (min-width: 768px) {
    .GalleryText-mobile {
      display: none; } }

@media (max-width: 767.98px) {
  .GalleryText-desktop {
    display: none; } }

@media (min-width: 768px) {
  .GalleryText-desktopMedia {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0; } }

@media (min-width: 1440px) {
  .GalleryText-desktopMedia {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    margin-left: 8.33333%;
    margin-bottom: 0; } }

.GalleryText-figureWrapper {
  height: 85vh;
  width: 100%;
  position: relative;
  overflow: hidden; }

.GalleryText-desktopFigure {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #c7cec5; }

.GalleryText-figure {
  position: relative;
  width: 100%;
  padding-bottom: 114.28571%;
  overflow: hidden;
  background: #c7cec5; }

.GalleryText-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .GalleryText-image.lazyloaded {
    opacity: 1; }

@media (min-width: 768px) {
  .GalleryText-prose {
    margin: auto;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 992px) {
  .GalleryText-prose {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.Hotspots {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .Hotspots {
      margin-top: 11.11111vw ;
      margin-bottom: 11.11111vw ; } }

.Hotspots-wrapper {
  position: relative; }

@media (min-width: 992px) {
  .Hotspots.--tight .Hotspots-col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

.Hotspots-figure {
  background-color: #c7cec5; }

.Hotspots-image {
  transition: opacity 0.6s;
  opacity: 0; }
  .Hotspots-image.lazyloaded {
    opacity: 1; }

.Hotspots-itemList,
.Hotspots-overlayList {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.Hotspots-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff; }

.Hotspots-dot {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transform: translate(-50%, -50%); }
  .Hotspots-dot:before, .Hotspots-dot:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%; }
  .Hotspots-dot:before {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff; }
  .Hotspots-dot:after {
    width: 50%;
    height: 50%;
    border: 2px solid #fff;
    transform-origin: 50% 50%;
    animation: dot-zoom 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite; }

.Hotspots-overlay {
  padding: 0rem;
  background-color: #fff;
  width: 20rem;
  display: none; }
  .fancybox .Hotspots-overlay {
    display: block; }

.Hotspots-overlayLink {
  color: inherit !important;
  text-decoration: none !important; }

.Hotspots-overlayFigure {
  padding-bottom: 100%;
  padding-bottom: 75%;
  background-color: #c7cec5;
  position: relative; }

.Hotspots-overlayImage {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .Hotspots-overlayImage.lazyloaded {
    opacity: 1; }

.Hotspots-overlayContent {
  padding: 1.5rem 1rem; }

.Hotspots-contentTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem;
  margin-bottom: 1rem; }
  .Hotspots-contentTitle .Icon {
    flex-shrink: 0;
    margin-left: 1rem; }

.Hotspots-contentDescription {
  margin: 0; }

@keyframes dot-zoom {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.3); }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3); } }

.HpSlider {
  overflow: hidden;
  margin-bottom: -1.5rem !important; }
  .HpSlider .swiper-slide {
    overflow: hidden; }
    .HpSlider .swiper-slide:last-child {
      margin-right: 0; }
  .HpSlider .swiper-pagination {
    left: 0;
    transform: none;
    text-align: left;
    left: 4.26667vw ;
    bottom: 2.13333vw ; }
    @media (min-width: 768px) {
      .HpSlider .swiper-pagination {
        left: 2.22222vw ; } }
    @media (min-width: 768px) {
      .HpSlider .swiper-pagination {
        bottom: 1.11111vw ; } }
    @media (min-width: 768px) {
      .HpSlider .swiper-pagination {
        left: 6.4vw ;
        bottom: 4.26667vw ; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .HpSlider .swiper-pagination {
      left: 3.33333vw ; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .HpSlider .swiper-pagination {
      bottom: 2.22222vw ; } }

.HpSlider-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4.26667vw ;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff; }
  @media (min-width: 768px) {
    .HpSlider-content {
      padding: 2.22222vw ; } }
  @media (min-width: 768px) {
    .HpSlider-content {
      padding: 6.4vw ; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .HpSlider-content {
      padding: 3.33333vw ; } }

.HpSlider-title {
  text-indent: -50px;
  padding-left: 50px;
  margin-bottom: 1.5rem;
  font-size: 40px;
  line-height: 1; }
  @media (min-width: 768px) {
    .HpSlider-title {
      margin-bottom: 2.77778vw ; } }
  @media (min-width: 992px) {
    .HpSlider-title {
      font-size: 72px; } }
  @media (min-width: 768px) {
    .HpSlider-title {
      font-size: 72px; } }
  .HpSlider-title span {
    opacity: 0;
    transition: 0.6s opacity; }
    .swiper-slide-active .HpSlider-title span,
    .swiper-slide-duplicate-active .HpSlider-title span {
      opacity: 1; }

.HpSlider-ctaLink {
  margin-left: 50px;
  color: #fff; }
  .HpSlider-ctaLink:hover {
    color: #fff;
    text-decoration: underline; }

.HpSlider-ctaNumber {
  position: absolute;
  transform: rotate(-90deg);
  font-size: calc(1rem - 2px); }

.HpSlider-ctaText {
  margin-left: 50px;
  color: #fff; }
  .HpSlider-ctaText:hover {
    color: #fff;
    text-decoration: underline; }

.HpSlider-figure {
  width: 100%;
  padding-bottom: 0;
  height: 60vh;
  position: relative;
  background: #c7cec5; }
  @media (min-width: 768px) {
    .HpSlider-figure {
      padding-bottom: 75%; } }
  @media (min-width: 992px) {
    .HpSlider-figure {
      padding-bottom: 66.66667%; } }
  @media (min-width: 1440px) {
    .HpSlider-figure {
      padding-bottom: 50%; } }
  .HpSlider-figure::after {
    opacity: 0.5;
    transition: 1s opacity;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black; }
    .swiper-slide-active .HpSlider-figure::after {
      background: black;
      opacity: 0.3; }

.HpSlider-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .HpSlider-image.lazyloaded {
    opacity: 1; }

.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.3px;
  width: 1rem;
  height: 1rem; }

.Icon-svg {
  width: 100%;
  height: 100%; }

.Icon--filters {
  width: 1.375rem;
  height: 0.875rem; }

.Icon--close {
  width: 1.25rem;
  height: 1.25rem; }

.Icon--menu {
  width: 1.5rem;
  height: 1.25rem; }

.Icon--arrow {
  width: 1.25rem;
  height: 1rem; }

.Icon--lens {
  width: 1.5rem;
  height: 1.5rem; }

.Icon--round-arrow {
  width: 1.125rem;
  height: 1.375rem; }

.IconList {
  margin-top: 2.5rem; }
  .IconList ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .Accordion .IconList {
    margin-top: -2.5rem; }

.IconList-wrapper + .IconList-wrapper {
  margin-top: 2.5rem; }

.IconList-title {
  margin-bottom: 1.5rem;
  font-weight: 700; }
  @media (min-width: 768px) {
    .IconList-title {
      margin-bottom: 2.77778vw ; } }

.IconList-list {
  display: flex; }

.IconList-figure {
  display: inline-block;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden; }
  .IconList-listItem:not(:last-child) .IconList-figure {
    margin-right: 1rem; }

.IconList-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/contain no-repeat; }
  .IconList-image.lazyloaded {
    opacity: 1; }

.Image.--full .Image-figureContainer {
  margin-left: -4.26667vw ;
  margin-right: -4.26667vw ; }
  @media (min-width: 768px) {
    .Image.--full .Image-figureContainer {
      margin-left: -2.22222vw ;
      margin-right: -2.22222vw ; } }

.Image:not(.--full) .Image-figureRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .Image:not(.--full) .Image-figureRow {
      margin-right: -1.11111vw;
      margin-left: -1.11111vw; } }

.Image:not(.--full) .Image-figureCol {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .Image:not(.--full) .Image-figureCol {
      padding-right: 1.11111vw;
      padding-left: 1.11111vw; } }

@media (min-width: 768px) {
  .Image.--narrow .Image-figureCol {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin-left: auto;
    margin-right: auto; } }

.Image-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 75%;
  background: #c7cec5; }
  @media (min-width: 768px) {
    .Image.--full .Image-wrapper {
      padding-bottom: 66.66667%; } }
  @media (min-width: 1440px) {
    .Image.--full .Image-wrapper {
      padding-bottom: 54.54545%; } }

.Image-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat;
  transition: opacity 0.6s;
  opacity: 0; }
  .Image-image.lazyloaded {
    opacity: 1; }

.Image-caption {
  color: #45433f;
  margin-top: 1rem; }
  @media (min-width: 768px) {
    .Image-caption {
      flex: 0 0 50%;
      max-width: 50%;
      margin-left: 8.33333%; }
      .Image.--narrow .Image-caption {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        margin-left: 16.66667%; } }
  @media (min-width: 992px) {
    .Image-caption {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
      .Image.--narrow .Image-caption {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        margin-left: 16.66667%; } }
  @media (min-width: 1440px) {
    .Image.--narrow .Image-caption {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-left: 16.66667%; } }

@media (min-width: 992px) {
  .ImageGrid-mainCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 767.98px) {
  .ImageGrid-col:first-child {
    margin-bottom: 3rem; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .ImageGrid-col:first-child {
      margin-bottom: 5.55556vw ; } }

@media (min-width: 768px) {
  .ImageGrid-col {
    flex: 0 0 50%;
    max-width: 50%; } }

.ImageGrid-imageWrapper {
  background: #c7cec5;
  margin-bottom: 1rem; }

.ImageGrid-image {
  transition: opacity 0.6s;
  opacity: 0; }
  .ImageGrid-image.lazyloaded {
    opacity: 1; }

.ImageGrid-caption {
  color: #45433f; }
  @media (min-width: 1440px) {
    .ImageGrid-caption {
      max-width: 80%; } }

@media (min-width: 768px) {
  .InspirationalDetail-galleryCol {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.InspirationalDetail-galleryCol {
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .InspirationalDetail-galleryCol {
      margin-bottom: 11.11111vw ; } }
  .InspirationalDetail-galleryCol p {
    margin: 4.5rem 0 0; }
  .InspirationalDetail-galleryCol p + p {
    margin: 1rem 0 0; }

@media (min-width: 768px) {
  .InspirationalDetail-formCol {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 768px) {
  .InspirationalDetail-formCol {
    flex: 0 0 50%;
    max-width: 50%; } }

.Logo {
  height: 100%;
  width: auto;
  fill: currentColor; }

.Menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  color: #fff; }
  .Menu a {
    color: #fff; }
  html:not(.show-menu) .Menu {
    pointer-events: none; }
  .Menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }

.Menu-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s; }
  html:not(.show-menu) .Menu-bg {
    opacity: 0; }

.Menu-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #312f2b;
  transform: translateX(110%);
  transition: transform 0.6s;
  left: 15%; }
  @media (min-width: 768px) {
    .Menu-panel {
      left: 50%; } }
  @media (min-width: 992px) {
    .Menu-panel {
      left: 65vw; } }
  html.show-menu .Menu-panel {
    transform: translateX(0); }

.Menu-header {
  height: 60px ;
  padding-right: 4.26667vw ;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 2; }
  @media (min-width: 768px) {
    .Menu-header {
      height: 80px ; } }
  @media (min-width: 768px) {
    .Menu-header {
      padding-right: 2.22222vw ; } }

.Menu-close {
  pointer-events: auto;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  color: #fff;
  outline: none !important; }

.Menu-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4.26667vw ;
  padding-top: 30px ;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media (min-width: 768px) {
    .Menu-content {
      padding: 2.22222vw ; } }
  @media (min-width: 768px) {
    .Menu-content {
      padding-top: 40px ; } }

.Menu-mainNavLabel {
  font-size: 12px;
  display: block;
  margin-bottom: 0.5rem; }

.Menu-mainNav {
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 20vh; }
  .Menu-mainNav > ul > li > a {
    font-size: 29.43469px;
    font-family: "Optima", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1;
    font-weight: 400;
    margin-bottom: 0.5rem;
    display: inline-block;
    line-height: 1; }
    @media (min-width: 320px) and (max-width: 1440px) {
      .Menu-mainNav > ul > li > a {
        font-size: calc( 29.43469px + 12.57976 * ( ( 100vw - 320px) / 1120 )); } }
    @media (min-width: 1440px) {
      .Menu-mainNav > ul > li > a {
        font-size: 42.01444px; } }
  .Menu-mainNav ul ul li {
    opacity: 0.7;
    font-size: 1.375rem;
    display: flex; }
    .Menu-mainNav ul ul li:first-child {
      padding-top: 1rem; }
    .Menu-mainNav ul ul li::before {
      content: '';
      width: 1.125rem;
      height: 1.375rem;
      margin-right: 0.5rem;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18px' height='22px' viewbox='0 0 18 22' style='fill: none; stroke: white; stroke-width: 1.3'%3E%3Cpath d='M16,15.5H8c-3.3,0-6-2.7-6-6v-8l0,0' /%3E%3Cpolyline points='11.1,10.6 16,15.5 11.1,20.5' /%3E%3C/svg%3E");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
  .Menu-mainNav .is-dropdown {
    margin-bottom: 2.5rem; }
    .Menu-mainNav .is-dropdown > a {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .Menu-mainNav .is-dropdown > a::after {
        content: "";
        width: 1.25rem;
        height: 1.25rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewbox='0 0 20 20' style='fill: none; stroke: white; stroke-width: 1.4'%3E%3Cline x1='18.2' y1='18.2' x2='1.8' y2='1.8' /%3E%3Cline x1='1.8' y1='18.2' x2='18.2' y2='1.8' /%3E%3C/svg%3E");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(10px) rotate(-135deg) scale(0.55);
        transition: transform 0.6s; }
      .Menu-mainNav .is-dropdown > a.is-open::after {
        transform: translateX(10px) rotate(0) scale(0.55); }

.Menu-accordionHeader {
  display: flex;
  align-items: center; }
  .Menu-accordionHeader h2 {
    margin-right: 1rem; }
  .Menu-accordionHeader .Icon {
    stroke-width: 1.82px; }
  .Menu-accordionHeader.is-open span {
    transform: rotate(0deg) scale(0.55); }

.Menu-accordionItem {
  display: flex; }
  .Menu-accordionItem a {
    padding-top: 0rem;
    margin-left: 0.5rem; }

.Menu-footer {
  margin-top: auto; }
  @media (min-width: 768px) {
    .Menu-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; } }

.Menu-footerNav li:not(:last-child) a {
  display: inline-block;
  margin-bottom: 0.5rem; }

.Menu-footerNav a {
  font-size: 1.375rem;
  font-family: "Optima", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0.5rem; }

@media (max-width: 767.98px) {
  .Menu-footerNav {
    margin-bottom: 1.25rem; } }

.Menu-footerLanguages ul {
  display: flex; }
  .Menu-footerLanguages ul li {
    opacity: 0.3; }
    .Menu-footerLanguages ul li:not(:last-child) {
      margin-right: 0.75rem; }
    .Menu-footerLanguages ul li.is-active {
      opacity: 1; }

.PageHeaderBreadCrumb ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.PageHeaderBreadCrumb li {
  display: inline-block;
  color: #45433f;
  margin-bottom: 0.25rem; }
  .PageHeaderBreadCrumb li:not(:first-child):before {
    content: "/";
    margin: 0 0.25rem 0 0.15rem; }

.PageHeaderBreadCrumb span {
  font-size: 26.49104px;
  opacity: 0.3;
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  @media (min-width: 320px) and (max-width: 1440px) {
    .PageHeaderBreadCrumb span {
      font-size: calc( 26.49104px + 9.4612 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    .PageHeaderBreadCrumb span {
      font-size: 35.95224px; } }

.PageHeaderBreadCrumb-subtitle {
  font-size: 1rem;
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: rgba(48, 46, 42, 0.5); }

@media (min-width: 768px) {
  .PageHeaderCollection-ContentCol {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 768px) {
  .PageHeaderCollection-ContentWrapper {
    position: sticky;
    top: 60px ; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .PageHeaderCollection-ContentWrapper {
      top: 80px ; } }

.PageHeaderCollection-HeadingCol {
  margin-bottom: 4.26667vw ; }
  @media (min-width: 768px) {
    .PageHeaderCollection-HeadingCol {
      margin-bottom: 2.22222vw ; } }

.PageHeaderCollection-title {
  margin: 0; }

.PageHeaderCollection-smallMediaCol {
  margin-bottom: 6.4vw ;
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 768px) {
    .PageHeaderCollection-smallMediaCol {
      margin-bottom: 3.33333vw ; } }
  @media (min-width: 768px) {
    .PageHeaderCollection-smallMediaCol {
      margin-bottom: 0;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.PageHeaderCollection-bigMediaCol {
  margin-left: auto; }
  @media (min-width: 768px) {
    .PageHeaderCollection-bigMediaCol {
      flex: 0 0 50%;
      max-width: 50%; } }

.PageHeaderCollection-HeadingCaption {
  color: #45433f; }

.PageHeaderCollection-smallMediaFigure {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
  background: #c7cec5; }

.PageHeaderCollection-smallMediaImage,
.PageHeaderCollection-bigMediaImage {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .PageHeaderCollection-smallMediaImage.lazyloaded,
  .PageHeaderCollection-bigMediaImage.lazyloaded {
    opacity: 1; }

.PageHeaderCollection-bigMediaFigure {
  position: relative;
  width: 100%;
  padding-bottom: 133.33333%;
  overflow: hidden;
  background: #c7cec5; }

.tpl-collection-list .PageHeaderSolid {
  background-color: #c7cec5; }
  .tpl-collection-list .PageHeaderSolid .custom-select {
    background-color: #c7cec5;
    border: 1px solid #fff;
    border-radius: 7px;
    color: #000;
    height: auto;
    padding: 0.75rem 1rem; }

.tpl-custom-list .PageHeaderSolid {
  background-color: #c7cec5; }
  .tpl-custom-list .PageHeaderSolid .custom-select {
    background-color: #c7cec5;
    border: 1px solid #fff;
    border-radius: 7px;
    color: #000;
    height: auto;
    padding: 0.75rem 1rem; }

.tpl-inspiration-list .PageHeaderSolid {
  background-color: #c7cec5; }
  .tpl-inspiration-list .PageHeaderSolid .custom-select {
    background-color: #c7cec5;
    border: 1px solid #fff;
    border-radius: 7px;
    color: #000;
    height: auto;
    padding: 0.75rem 1rem; }

.tpl-project-list .PageHeaderSolid {
  background-color: #c7cec5; }
  .tpl-project-list .PageHeaderSolid .custom-select {
    background-color: #c7cec5;
    border: 1px solid #fff;
    border-radius: 7px;
    color: #000;
    height: auto;
    padding: 0.75rem 1rem; }

.tpl-distributors .PageHeaderSolid {
  background-color: #c7cec5; }
  .tpl-distributors .PageHeaderSolid .custom-select {
    background-color: #c7cec5;
    border: 1px solid #fff;
    border-radius: 7px;
    color: #000;
    height: auto;
    padding: 0.75rem 1rem; }

.tpl-search .PageHeaderSolid {
  background-color: #c7cec5; }
  .tpl-search .PageHeaderSolid .custom-select {
    background-color: #c7cec5;
    border: 1px solid #fff;
    border-radius: 7px;
    color: #000;
    height: auto;
    padding: 0.75rem 1rem; }

.tpl-downloads .PageHeaderSolid {
  background-color: whitesmoke; }

.PageHeaderSolid {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #c7cec5; }
  @media (min-width: 768px) {
    .PageHeaderSolid {
      padding-top: 11.11111vw ;
      padding-bottom: 11.11111vw ; } }
  .PageHeaderSolid h1 em {
    color: #fff;
    font-style: normal; }

@media (min-width: 992px) {
  .PageHeaderSolid-mainCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

.PageHeaderSolid-row {
  align-items: center; }

@media (min-width: 768px) {
  .PageHeaderSolid-titleCol {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .PageHeaderSolid-titleCol {
    flex: 0 0 60%;
    max-width: 60%; } }

@media (max-width: 767.98px) {
  .PageHeaderSolid-selectCol {
    position: relative;
    top: 6rem;
    transform: translateY(-4.26667vw);
    font-size: 16px; }
    .PageHeaderSolid-selectCol > *:not(:first-child) {
      margin-top: 1rem; }
    .PageHeaderSolid-selectCol > *:first-child {
      margin-top: -2.5rem; }
    .PageHeaderSolid-selectCol .custom-select {
      font-size: 16px; } }

@media (min-width: 768px) {
  .PageHeaderSolid-selectCol {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    display: flex;
    align-items: center; }
    .PageHeaderSolid-selectCol > *:not(:first-child) {
      margin-left: 1rem; }
    .PageHeaderSolid-selectCol div {
      flex-shrink: 0; } }

@media (min-width: 992px) {
  .PageHeaderSolid-selectCol {
    flex: 0 0 40%;
    max-width: 40%; } }

@media (min-width: 768px) {
  .PageHeaderSolid-searchCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 992px) {
  .PageHeaderSolid-searchCol {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 768px) {
  .PageHeaderText-col {
    flex: 0 0 50%;
    max-width: 50%; } }

.ProductGallery {
  position: relative;
  margin-bottom: 4.5rem;
  user-select: none; }
  .ProductGallery .swiper-pagination {
    left: 0;
    transform: none;
    text-align: left;
    margin-top: 1rem; }
  .ProductGallery .swiper-pagination-bullet {
    opacity: 0.2; }
    .ProductGallery .swiper-pagination-bullet::before, .ProductGallery .swiper-pagination-bullet::after {
      display: none; }
    @media (min-width: 768px) {
      .ProductGallery .swiper-pagination-bullet:not(:last-child) {
        margin: 0 1rem 0 0 !important; } }
  .ProductGallery .swiper-pagination-bullet-active {
    opacity: 1; }

.ProductGallery-figure {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  background: #c7cec5;
  overflow: hidden; }

.ProductGallery-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .ProductGallery-image.lazyloaded {
    opacity: 1; }

.ProductImage-image {
  transition: opacity 0.6s;
  opacity: 0; }
  .ProductImage-image.lazyloaded {
    opacity: 1; }

.ProjectForm {
  width: 100%; }
  @media (max-width: 767.98px) {
    .ProjectForm select:not(:first-child) {
      margin-top: 1rem; } }
  @media (min-width: 768px) {
    .ProjectForm {
      display: flex; }
      .ProjectForm select:not(:first-child) {
        margin-left: 1rem; } }

.ProjectList {
  min-height: 30vh;
  margin-top: 8.53333vw ;
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .ProjectList {
      margin-top: 4.44444vw ; } }
  @media (min-width: 768px) {
    .ProjectList {
      margin-bottom: 11.11111vw ; } }

@media (min-width: 992px) {
  .ProjectList-mainCol {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 992px) {
  .InspirationList .ProjectList-mainCol {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .ProjectList-col {
    flex: 0 0 50%;
    max-width: 50%; }
    .ProjectList-col:nth-child(8n + 1), .ProjectList-col:nth-child(8n + 2) {
      flex: 0 0 50%;
      max-width: 50%; }
    .ProjectList-col:nth-child(8n + 3), .ProjectList-col:nth-child(8n + 8) {
      flex: 0 0 100%;
      max-width: 100%; }
      .ProjectList-col:nth-child(8n + 3) .Thumbnail, .ProjectList-col:nth-child(8n + 8) .Thumbnail {
        padding-bottom: 56.25%; } }

@media (min-width: 768px) {
  .InspirationList .ProjectList-col:nth-child(odd), .InspirationList .ProjectList-col:nth-child(even) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
    .InspirationList .ProjectList-col:nth-child(odd) .Thumbnail, .InspirationList .ProjectList-col:nth-child(even) .Thumbnail {
      padding-bottom: 75%; } }

@media (min-width: 1440px) {
  .InspirationList .ProjectList-col:nth-child(odd), .InspirationList .ProjectList-col:nth-child(even) {
    flex: 0 0 25%;
    max-width: 25%; } }

.ProjectList-row {
  margin-top: -3rem; }
  @media (min-width: 768px) {
    .ProjectList-row {
      margin-top: -5.55556vw ; } }

.ProjectList-col {
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .ProjectList-col {
      margin-top: 5.55556vw ; } }

.ProjectList-wrapper {
  min-height: 30vh;
  margin-top: 8.53333vw;
  margin-bottom: 6rem; }

.ProjectPreview-title,
.ProjectPreview-subtitle {
  margin: 0; }

.ProjectPreview-title {
  font-family: "sailec", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  margin-bottom: 0.25rem; }

.ProjectPreview-subtitle {
  color: rgba(48, 46, 42, 0.5); }

.ProjectRelated-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .ProjectRelated-header {
      margin-bottom: 2.77778vw ; } }

.ProjectRelated-cardRow {
  margin-top: -1.5rem; }
  @media (min-width: 768px) {
    .ProjectRelated-cardRow {
      margin-top: -2.77778vw ; } }

.ProjectRelated-cardCol {
  margin-top: 1.5rem; }
  @media (min-width: 768px) {
    .ProjectRelated-cardCol {
      margin-top: 2.77778vw ; } }
  @media (min-width: 768px) {
    .ProjectRelated-cardCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
      .ProjectRelated-cardCol:nth-last-child(2):first-child, .ProjectRelated-cardCol:nth-last-child(2):first-child ~ .ProjectRelated-cardCol {
        flex: 0 0 50%;
        max-width: 50%; } }

.ProjectRelated-headerLink:hover {
  text-decoration: underline; }

.Prose > *:first-child {
  margin-top: 0 !important; }

.Prose > *:last-child {
  margin-bottom: 0 !important; }

.Prose h1, .Prose h2, .Prose h3, .Prose h4, .Prose h5, .Prose h6 {
  margin-top: 3rem;
  margin-bottom: 1.5rem; }

.SearchForm-nav {
  list-style: none;
  padding: 0;
  margin: 0 0 0.25rem 0;
  display: flex; }
  .SearchForm-nav label {
    margin: 0;
    cursor: pointer;
    color: #45433f; }
  .SearchForm-nav input {
    display: none; }
  .SearchForm-nav input:checked + span {
    text-decoration: underline;
    color: #000; }
  .SearchForm-nav li:not(:first-child) {
    margin-left: 1rem; }

.SearchForm-form {
  border-bottom: 1px solid #fff; }

.SearchForm-inputField {
  display: flex;
  align-items: center;
  height: 3.5rem; }

.SearchForm-textInput {
  font-family: "Optima", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-size: 23.51351px;
  flex: 1;
  border: 0;
  background: #c7cec5;
  height: 100%;
  outline: none;
  margin: 0;
  padding: 0; }
  @media (min-width: 320px) and (max-width: 1440px) {
    .SearchForm-textInput {
      font-size: calc( 23.51351px + 6.62688 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    .SearchForm-textInput {
      font-size: 30.14039px; } }

.SearchForm-buttonInput {
  border: 0;
  background: #c7cec5;
  height: 100%;
  padding-right: 0; }
  .SearchForm-buttonInput .Icon {
    transition: transform 0.3s; }
  .SearchForm-buttonInput:hover .Icon {
    transform: translateX(0.25rem); }

.SearchResults {
  min-height: 30vh;
  margin-top: 8.53333vw ;
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .SearchResults {
      margin-top: 4.44444vw ; } }
  @media (min-width: 768px) {
    .SearchResults {
      margin-bottom: 11.11111vw ; } }

.SearchResults-itemRow {
  margin-top: -3rem; }
  @media (min-width: 768px) {
    .SearchResults-itemRow {
      margin-top: -5.55556vw ; } }

.SearchResults-itemCol {
  margin-top: 3rem;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .SearchResults-itemCol {
      margin-top: 5.55556vw ; } }
  @media (min-width: 768px) {
    .SearchResults-itemCol {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .SearchResults-itemCol {
      flex: 0 0 25%;
      max-width: 25%; } }

.SearchResults-alertCol {
  margin: auto;
  color: #45433f; }
  @media (min-width: 768px) {
    .SearchResults-alertCol {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .SearchResults-alertCol {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.Section {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .Section {
      margin-top: 11.11111vw ;
      margin-bottom: 11.11111vw ; } }

.SiteFooter {
  padding-top: 8.53333vw ;
  padding-bottom: 4.26667vw ;
  color: #fff;
  background: #312f2b; }
  @media (min-width: 768px) {
    .SiteFooter {
      padding-top: 4.44444vw ; } }
  @media (min-width: 768px) {
    .SiteFooter {
      padding-bottom: 2.22222vw ; } }
  .SiteFooter ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .SiteFooter a {
    color: #fff; }

.SiteFooter-navCol li {
  font-family: "Optima", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-size: 26.49104px; }
  @media (min-width: 320px) and (max-width: 1440px) {
    .SiteFooter-navCol li {
      font-size: calc( 26.49104px + 9.4612 * ( ( 100vw - 320px) / 1120 )); } }
  @media (min-width: 1440px) {
    .SiteFooter-navCol li {
      font-size: 35.95224px; } }

@media (max-width: 767.98px) {
  .SiteFooter-navCol {
    margin-bottom: 3rem; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .SiteFooter-navCol {
      margin-bottom: 5.55556vw ; } }

@media (min-width: 768px) {
  .SiteFooter-navCol {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (max-width: 767.98px) {
  .SiteFooter-addressCol {
    margin-bottom: 3rem; } }
  @media (max-width: 767.98px) and (min-width: 768px) {
    .SiteFooter-addressCol {
      margin-bottom: 5.55556vw ; } }

@media (min-width: 768px) {
  .SiteFooter-addressCol {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.SiteFooter-addressCol a {
  text-decoration: underline; }

@media (min-width: 768px) {
  .SiteFooter-contactCol {
    flex: 0 0 25%;
    max-width: 25%; } }

.SiteFooter-mainRow {
  margin-bottom: 3rem; }
  @media (min-width: 768px) {
    .SiteFooter-mainRow {
      margin-bottom: 5.55556vw ; } }

p.SiteFooter-sectionTitle {
  font-weight: 700; }
  @media (min-width: 768px) {
    p.SiteFooter-sectionTitle {
      margin-bottom: 1rem; } }

.SiteFooter-colophonCol {
  display: flex;
  justify-content: space-between;
  color: #AEAEAE; }
  .SiteFooter-colophonCol a {
    color: #AEAEAE; }
  .SiteFooter-colophonCol p {
    margin: 0; }
  .SiteFooter-colophonCol p span:not(:last-child) {
    margin-right: 0.5rem; }
  @media (max-width: 767.98px) {
    .SiteFooter-colophonCol {
      flex-direction: column; }
      .SiteFooter-colophonCol p span {
        display: block; } }

.tpl-collection-list .SiteHeader:before {
  background-color: #c7cec5; }

.tpl-custom-list .SiteHeader:before {
  background-color: #c7cec5; }

.tpl-inspiration-list .SiteHeader:before {
  background-color: #c7cec5; }

.tpl-project-list .SiteHeader:before {
  background-color: #c7cec5; }

.tpl-distributors .SiteHeader:before {
  background-color: #c7cec5; }

.tpl-search .SiteHeader:before {
  background-color: #c7cec5; }

.tpl-downloads .SiteHeader:before {
  background-color: whitesmoke; }

.SiteHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  pointer-events: none;
  transition: transform 0.2s; }
  .SiteHeader::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: -1; }
  .mobile .SiteHeader,
  .tablet .SiteHeader {
    position: fixed; }
  .SiteHeader.scroll-down {
    position: fixed;
    animation: scrollDown 0.3s forwards; }
  .SiteHeader.scroll-up {
    position: fixed;
    animation: scrollUp 0.3s forwards; }

.SiteHeader-container {
  height: 60px ;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .SiteHeader-container {
      height: 80px ; } }

.SiteHeader-logo {
  pointer-events: auto;
  height: 50%;
  margin-right: auto; }

.SiteHeader-menuToggle {
  pointer-events: auto;
  position: relative;
  padding: 0;
  border: 0;
  font-size: 0;
  outline: none !important;
  cursor: pointer;
  margin-left: 0.5rem;
  background-color: transparent; }

.SiteHeader-search {
  pointer-events: auto; }

@keyframes scrollDown {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, -100%, 0); } }

@keyframes scrollUp {
  0% {
    transform: translate3d(0, -100%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.Sizes {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem; }
  :not(.Accordion) + .Sizes {
    border-top: 1px solid rgba(151, 151, 151, 0.3); }
  .Sizes ul {
    list-style: none;
    padding: 0;
    margin: 0; }

.Sizes-list {
  display: flex;
  flex-wrap: wrap; }

.Sizes-contentWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2rem; }
  @media (min-width: 768px) {
    .Sizes-contentWrapper {
      width: 50%; } }

.Sizes-title {
  margin-bottom: 1rem; }

.Sizes-icon {
  width: 50px;
  padding-bottom: 50px;
  background: #ddd;
  height: 0; }

.Sizes-content {
  opacity: 0.6;
  margin-left: 1.25rem;
  margin-right: 1rem; }

.Sizes-contentTitle {
  font-weight: 700;
  margin: 0; }

.SvgLibrary {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden; }

.swiper-pagination {
  pointer-events: auto;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  z-index: 10; }

.swiper-pagination-bullet {
  position: relative;
  margin: 0 !important;
  background: transparent;
  opacity: 1; }
  .swiper-pagination-bullet:not(:last-child) {
    margin: 0 1rem 0 0 !important; }
  @media (min-width: 768px) {
    .swiper-pagination-bullet:not(:last-child) {
      margin: 0 1.5rem 0 0 !important; } }
  .swiper-pagination-bullet:before, .swiper-pagination-bullet:after {
    content: "";
    position: absolute;
    height: 11px;
    width: 11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.4);
    border-radius: 50%; }
  .swiper-pagination-bullet:before {
    border: 1px solid #fff;
    transition: 0.4s transform; }
  .swiper-pagination-bullet:after {
    background: #fff;
    transition: 0.4s transform, 0.4s opacity; }

.swiper-pagination-bullet-active:before {
  transform: translate(-50%, -50%) scale(1);
  transition: 0.4s transform; }

.swiper-pagination-bullet-active:after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
  transition: 0.4s transform, 0.4s opacity; }

@media (min-width: 768px) {
  .Text-prose {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin: auto; } }

@media (min-width: 992px) {
  .Text-prose {
    flex: 0 0 50%;
    max-width: 50%;
    margin: auto; } }

@media (min-width: 768px) {
  .Text.--alignRight .Text-prose {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    margin-left: auto;
    margin-right: 0; } }

.Thumbnail {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  margin-bottom: 1rem;
  background: #c7cec5;
  overflow: hidden; }

.Thumbnail-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 1s transform; }
  a:hover .Thumbnail-wrapper {
    transition: 2s transform;
    transform: scale(1.1); }
  a:hover .Thumbnail-wrapper.Thumbnail-disableZoom {
    transition: none;
    transform: none; }
    a:hover .Thumbnail-wrapper.Thumbnail-disableZoom .Thumbnail-image:nth-child(2) {
      opacity: 0; }

.Thumbnail-image {
  transition: opacity 0.6s;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat; }
  .Thumbnail-image.lazyloaded {
    opacity: 1; }

.CustomizationList{
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .Accordion & {
    margin-top: -2.5rem;
  }
}

.CustomizationList-title {
  font-weight: 700;
  @include content-spacer("margin-bottom", 0.5);
}

.CustomizationList-row {
  margin-top: -2.5rem;
}

.CustomizationList-col {
  @include make-col(6);
  margin-top: 2.5rem;
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
}

.CustomizationList-label {
  opacity: 0.6;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.CustomizationList-figure {
  position: relative;
  width: 100%;
  padding-bottom: 3 / 4 * 100%;
  background: $color-media-placeholder;
}

.CustomizationList-image {
  @include lazyloaded();
  @include imageBg();
}

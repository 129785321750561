/*
  ------------------------------------------------------------------
  --- IMAGES -------------------------------------------------------
  ------------------------------------------------------------------
*/

@mixin lazyloaded($transition: #{opacity .6s}) {
  @if($transition != false) {
    transition: $transition;
  }
  opacity: 0;
  &.lazyloaded {
    opacity: 1;
  }
}


@mixin absoluteAllSides() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin imageBg() {
  @include absoluteAllSides;
  background: center/cover no-repeat;
}

/*
  ------------------------------------------------------------------
  --- SECTIONS -----------------------------------------------------
  ------------------------------------------------------------------
*/

@mixin section() {
  @include section-spacer(("margin-top","margin-bottom"));
}

@mixin section-spacer($property, $factor: 1) {
  @each $prop_single in $property {
    #{$prop_single} : 6rem * $factor;
  }
      @include res-prop($property, $grid-gutter-widths, (sm: $factor * 5));
}

@mixin content-spacer($property, $factor: 1) {
  @include section-spacer($property, $factor * 0.5);
}

/*
  ------------------------------------------------------------------
  --- Typography ---------------------------------------------------
  ------------------------------------------------------------------
*/

@mixin heading() {
  font-family: $font-family-serif;
  line-height: $headings-line-height;
  font-weight: $headings-font-weight;
  margin-bottom: $headings-margin-bottom;
}

@mixin h1-large() {
  @include ms-respond(font-size, 35.5); // 60px
}

@mixin h1() {
  @include ms-respond(font-size, 32); // 52px
  line-height: 1.2;
  // font-size: $font-size-h1;
}

@mixin h1-small() {
  @include ms-respond(font-size, 18.7); // 30px
  // font-size: $font-size-h1-small;
}

@mixin h2() {
  @include ms-respond(font-size, 29); // 46px
  // font-size: $font-size-h2;
}

@mixin h3() {
  @include ms-respond(font-size, 26.8); // 42px
  // font-size: $font-size-h3;
}

@mixin h4() {
  @include ms-respond(font-size, 23); // 36px
  // font-size: $font-size-h4;
}

@mixin h5() {
  @include ms-respond(font-size, 18.7); // 30px
  // font-size: $font-size-h5;
}

@mixin h6() {
  @include ms-respond(font-size, 13.5); // 24px
  // font-size: $font-size-h6;
  // font-size: 24px;
}

@mixin p-xlarge() {
  font-size: $font-size-p-xlarge;
}

@mixin p-large() {
  // font-size: $font-size-p-large;
  font-size: 20px;
}

@mixin p-small() {
  font-size: $font-size-p-small;
}

@mixin p-xsmall() {
  font-size: $font-size-p-xsmall;
}

.CollectionList-col {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(xxl) {
    @include make-col(4);
  }
}

.CollectionList-row {
  @include content-spacer("margin-top", -1);
}

.CollectionList-col {
  @include content-spacer("margin-top");
}

.CollectionList-bg {
  @include media-breakpoint-up(sm) {
    @include absoluteAllSides();
    position: fixed;
    cursor: pointer;
    z-index: 10;
  }

  html:not(.show-modal) & {
    pointer-events: none;
    opacity: 0;
  }
}

.CollectionNav {
  background-color: color('green-1');
  margin-top: -3.6rem;
  color: color('black-18');
}

.CollectionNav {
  border-top: 1px solid rgba(#fff, 0.8);
  border-bottom: 1px solid rgba(#fff, 0.8);
  cursor: pointer;
}

.CollectionNav-heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  position: relative;
}

.CollectionNav-headingFilter {
  .Icon {
    margin-left: 0.25rem;
  }
}

.CollectionNav-headingCounter {
  color: $color-text-light;
}

.Sizes {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  :not(.Accordion) + & {
    border-top: 1px solid $color-list-border;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.Sizes-list {
  display: flex;
  flex-wrap: wrap;
}

.Sizes-contentWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  @include media-breakpoint-up(sm) {
    width: 50%;
  }
}

.Sizes-title {
  margin-bottom: 1rem;
}

.Sizes-icon {
  width: 50px;
  padding-bottom: 50px;
  background: #ddd;
  height: 0;
}


.Sizes-content {
  opacity: 0.6;
  margin-left: 1.25rem;
  margin-right: 1rem;
}

.Sizes-contentTitle {
  font-weight: 700;
  margin: 0;
}

// default variables, overwrite them in variables.scss

$grid-container-paddings: (
  xs: 16px,
  sm: 32px,
  md: 32px,
  lg: 64px,
  xl: 64px
) !default;

$grid-gutter-widths: (
  xs: 16px,
  sm: 32px,
  md: 32px,
  lg: 32px,
  xl: 32px
) !default;


/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutters: $grid-container-paddings) {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding-right: $gutter;
      padding-left:  $gutter;
    }
  }
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-gutters($gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding-right: ($gutter / 2);
      padding-left:  ($gutter / 2);
    }
  }
}

@mixin make-row($gutters: $grid-gutter-widths) {
  display: flex;
  flex-wrap: wrap;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      margin-right: ($gutter / -2);
      margin-left:  ($gutter / -2);
    }
  }
}

@mixin make-col-ready($gutters: $grid-gutter-widths) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  @include make-gutters($gutters);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // width: percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}

.HpSlider {
  overflow: hidden;
  margin-bottom: -1.5rem !important;
  .swiper-slide {
    overflow: hidden;
    &:last-child {
      margin-right: 0;
    }
  }
  .swiper-pagination {
    left: 0;
    transform: none;
    text-align: left;
    @include res-prop(("left"), $grid-container-paddings);
    @include res-prop(("bottom"), $grid-container-paddings, 0.5);
    @include media-breakpoint-up(sm) {
      @include res-prop(("left"), $grid-container-paddings, 1.5);
      @include res-prop(("bottom"), $grid-container-paddings, 1);
    }
  }
}

.HpSlider-content {
  @include absoluteAllSides();
  @include res-prop("padding", $grid-container-paddings);
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  @include media-breakpoint-up(sm) {
    @include res-prop("padding", $grid-container-paddings, 1.5);
  }
}

.HpSlider-title {
  text-indent: -50px;
  padding-left: 50px;
  @include content-spacer("margin-bottom", 0.5);
  font-size: 40px;
  line-height: 1;
  @include media-breakpoint-up(md) {
    font-size: 72px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 72px;
  }
  span {
    opacity: 0;
    transition: 0.6s opacity;
    .swiper-slide-active &,
    .swiper-slide-duplicate-active & {
      opacity: 1;
    }
  }
}

.HpSlider-ctaLink {
  margin-left: 50px;
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.HpSlider-ctaNumber {
  position: absolute;
  transform: rotate(-90deg);
  font-size: calc(1rem - 2px);
}

.HpSlider-ctaText {
  margin-left: 50px;
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.HpSlider-figure {
  width: 100%;
  padding-bottom: 0;
  height: 60vh;
  position: relative;
  background: $color-media-placeholder;
  @include media-breakpoint-up(sm) {
    padding-bottom: 3 / 4 * 100%;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 3 / 4.5 * 100%;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 3 / 6 * 100%;
  }
  &::after {
    .swiper-slide-active & {
      background: rgba(0 , 0, 0, 1);
      opacity: 0.3;
    }
    opacity: 0.5;
    transition: 1s opacity;
    content: "";
    display: block;
    @include absoluteAllSides();
    background: rgba(0 , 0, 0, 1);
  }
}

.HpSlider-image {
  @include lazyloaded();
  @include imageBg();
}

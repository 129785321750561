.fancybox-slide--html {
  .fancybox-close-small {
    background: #fff;
    border-radius: 50%;
    margin: 7.5px;
    padding: 5px;
    height: 30px;
    width: 30px;
  }
}

.fancybox-button {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancybox-iconClose {
  //custom class
  position: relative;
  flex-shrink: 0;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s;
    transform: rotate(45deg);
  }
  &::before {
    background: url(svg("plus", "fill: none; stroke: #fff; stroke-width: 1px")) center/contain no-repeat;
    .fancybox-hotspots & {
      background: url(svg("plus", "fill: none; stroke: #000; stroke-width: 1px")) center/contain no-repeat;
    }
  }
}

.fancybox--overlay {
  .fancybox-slide--html {
    padding: 0;
  }

  .fancybox-content {
    padding: 0;
    width: 100%;
  }

  .fancybox-toolbar {
    @include res-prop("height", $site-header-heights);
  }

  .fancybox-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background: $color-fancybox-button;
    border-radius: 50%;
    @include res-prop("margin-right", $grid-container-paddings, 0.5);
    svg {
      color: $color-text-gray;
    }
  }

  .fancybox-loading {
    border: 2px solid #888;
    border-bottom-color: #fff;
  }
}

.CustomList {
  margin-top: 1rem;
  @include section-spacer("margin-bottom");
  color: $color-text-gray;
  a {
    color: $color-text-gray;
  }
}

.CustomList-filterCol {
  .--hasFilter & {
    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }
    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }
}

.CustomList-filterColWrapper {
  @include media-breakpoint-up(sm) {
    @include res-prop("top", $site-header-heights);
    position: sticky;
    height: auto;
  }
}

.CustomList-filterHeader,
.CustomList-listHeader {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-up(sm) {
    height: 5rem;
  }
}

.CustomList-listHeader {
  .CustomList:not(.--hasFilter) & {
    display: none;
  }
}

.CustomList-listSeoToggle {
  line-height: 1;
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  cursor: pointer;
  &::before {
    content: "?";
    font-size: 13px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.CustomList-listSeoText {
  span {
    display: block;
    padding-bottom: 1rem;
  }
}

.CustomList-filterHeader {
  @include media-breakpoint-down(xs) {
    border-bottom: 1px solid rgba(#000, 0.1);
  }
}

.CustomList-filterHeaderButton {
  cursor: pointer;
  @include media-breakpoint-up(sm) {
    pointer-events: none;
  }
}

.CustomList-filterHeaderDelete {
  display: none;
  z-index: 1;

  &.is-active {
    display: block;
  }
}

.CustomList-listCol {
  @include content-spacer("margin-bottom", -1);
  .--hasFilter & {
    @include media-breakpoint-up(sm) {
      @include make-col(8);
    }
    @include media-breakpoint-up(md) {
      @include make-col(9);
    }
  }
}

.CustomList-listRow {
  .CustomList:not(.--hasFilter) & {
    margin-top: 5rem;
    @include media-breakpoint-down(xs) {
      @include res-prop("margin-top", $grid-container-paddings);
    }
  }
}

.CustomList-listInnerCol {
  @include content-spacer("margin-bottom");
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
  .--hasFilter & {
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }
}
